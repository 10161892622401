.avatar {
  background-color: #FFD9DF;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  display: flex;
}

.avatar img {
  height: 80px;
  width: 80px;
  border-radius: 100%;
}

.naming-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: #F3F3F4;
  border-radius: 100px;
  color: #4A4A51;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding-top: 4px;
}

.naming-avatar--small {
  height: 40px;
  width: 40px;
  font-size: 12px;
  padding-top: 2px;
}

.avatar__verified {
  display: flex;
  align-items: flex-end;
  margin-left: -29px;
  margin-bottom: 1px;
}

.avatar__verified img {
  height: 28.57px;
  width: 28.57px;
}

.naming-avatar {
  font-size: 24px;
  padding-top: 10px;
}