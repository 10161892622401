.status-tag__icon {
  display: flex;
  align-items: center;
}

.status-tag {
  background: #EDFCF4;
  padding-top: 0.5px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  border-radius: 16px;
  height: 20px;
}

.status-tag--small {
  padding-bottom: 1px;
}

.status-tag__title {
  padding-left: 5px;
  /* padding-top: 2px; */
  font-weight: 500;
  color: #027A48;
  padding-top: 2px;
}

.status-tag__title p {
  margin: 0;
  line-height: 18px;
  font-size: 12px;
}

.status-tag--red {
  color: #ED6767;
  background-color: #FFEDED;
}

.status-tag--red .status-tag__title {
  color: #ED6767;
}

.status-tag--gray {
  color: #BDBDBD;
  background-color: #F5F5F5;
}

.status-tag--gray .status-tag__title {
  color: #7A7A86;
}

.status-tag--small .status-tag__title {
  font-size: 12px;
  font-weight: 500;
  padding-top: 3px;
}

.status-tag__circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #13b76a;
  margin-top: 1.2px;
}

.status-tag--red .status-tag__circle {
  background-color: #ED6767;
}

.status-tag--gray .status-tag__circle {
  background-color: #BDBDBD;
}