.light-menu {
  display: flex;
  border: 1px solid #D9D9DE;
  border-radius: 8px;
  max-width: max-content;
  max-height: max-content;
}

.light-menu__item {
  padding-top: 12px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid #F3F3F4;
  border-radius: 0px;
  line-height: 14px;
  max-height: max-content;
}

.light-menu__item:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.light-menu__item:last-of-type {
  border-right: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.light-menu__item span {
  font-size: 14px;
  font-weight: 600;
  color: #38383e;
}

.light-menu__item--selected {
  background-color: #F3F3F4;
}

.light-menu--small .light-menu__item--selected {

}

.light-menu__item:hover {
  background-color: #f8f8f8;
}

.light-menu__item:active {
  background-color: #efefef;
}

.light-menu__item--selected:hover {
  background-color: #f3f3f4;
}

.light-menu__item--selected:active {
  background-color: #efefef;
}