.login {
  font-size: 12px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #B6B6B6;
  max-height: 97vh;
}

.login__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.login__create-account {
  padding-top: 13px;
}

.login__create-account a {
  color: #FE8398;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}

.login__logo img {
  height: 42px;
  width: 123px;
}

.login__content {
  height: 100vh;
  padding: 0 64px;
  padding-top: 40px;
  padding-bottom: 0;
  background-color: #FEFEFF;
  width: 50%;
}

@media screen and (max-width: 1000px) {
  .login__content {
    padding-left: 24px;
    padding-right: 24px;
  }
}


.login__body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 80px;
  height: 90%;
}

.login__container {
  min-width: 400px;
  max-width: 400px;
  align-self: center;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 24px;
  padding-right: 24px;
}

.login__title h1 {
  font-size: 32px;
  color: black;
  font-weight: 600;
  text-align: center;
}

.login__subtitle p {
  font-size: 18px;
  color: #7A7A86;
  text-align: center;
  padding-top: 4px;
}

.login__forms {
  padding-top: 24px;
}

.login__forms__email {
  padding-bottom: 10px;
}

.login__forms__password__error {
  padding-top: 5px;
  text-align: left;
  color: #d78181;
}

.login__forget-password {
  text-align: right;
  padding-top: 10px;
}

.login__forget-password a {
  color: #B45D6C;
  text-decoration: none;
  font-size: 14px;
}

.login__forget-password a:hover {
  color: #88a8e8;
  text-decoration: none;
}

.login__action {
  padding-top: 25px;
}

.login__social {
  padding-top: 16px;
}

.login__social__google button {
  margin-top: 14px;
  background-color: white;
  border: 2px #F3F3F4 solid;
  border-radius: 16px;
  width: 100%;
  height: 54px;
  display: flex;
  color: #38383E;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.login__social__google p {
  padding-left: 12px;
  padding-top: 4px;
}

.login__social__google button:hover {
  background-color: #F3F3F4;
  border: 1px #D9D9DE solid;
  color: #38383E;
  width: 100%;
}

.login__social__google button:active {
  background-color: rgb(238, 238, 238) !important;
  border: 2px #F5F7FB solid !important;
  width: 100%;
}

h1 {
  color: black;
}

.login__forms__email {
  padding-bottom: 18px !important;
}

.login__social__title {
  font-size: 14px;
  color: #868693;
  text-align: center;
}

.login__banner {
  width: 50%;
  height: 100vh;
  padding: 24px;
  padding-left: 0;
}

@media screen and (max-width: 1100px) {
  .login__banner {
    display: none;
  }

  .login__content {
    width: 100%;
  }
}

.login__banner__image {
  background-image: url("../../assets/imgs/login-banner.svg");
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: flex-end;
}

.login__banner__content {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 96px;
  width: 100%;
  border-radius: 20px;
  background-color: rgba(153, 153, 153, 0.05);
  backdrop-filter: blur(15px);
  padding: 20px;
}

@media screen and (max-width: 1100px) {
  .login__banner__content {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.login__banner__title {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login__banner__title h1 {
  align-self: center;
  font-size: 32px;
  font-weight: 600;
  max-width: 500px;
  color: white;
}

.login__banner__subtitle {
  color: white;
  font-size: 20px;
  padding-top: 8px;
  text-align: center;
}