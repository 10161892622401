.default-table {
    padding: 0;
    min-height: 118px;
    background-color: white;
    border-radius: 16px;
    border: 2px solid #F3F3F4;
    min-height: 602px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.table__header {
    background-color: #FAFAFA;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
    height: 44px;
    display: flex;
    padding-top: 15px;
    padding-bottom: 8px;
    text-align: center;
    font-size: 12px;
    color: #868693;
    font-weight: 500;
    border-bottom: 1px solid #FAFAFA;
}

.table__row {
    width: 100%;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    text-align: center;
    font-size: 14px;
    color: #000000;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid #F3F3F4;
}

.table__row:hover {
    background-color: #FFFAFB;
}


.table__row:last-of-type {
    border-bottom: 0;
}

.column__header {
    display: flex;
    align-items: left;
    padding-left: 24px;
    font-size: 12px;
    color: #868693;
}

.column__value {
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 10px;
    padding-left: 24px;
    text-align: left;
    line-height: 19px;
}

.default-table__footer {
    padding-top: 20.5px;
    padding-bottom: 20.5px;
    border-top: 1px solid #F3F3F4;
}