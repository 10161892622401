.image-selector__text {
  height: 150px;
  width: 150px;
  background-color: #F3F3F4;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-selector__text p {
  color: #9E9EA9;
  font-size: 16px;
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  text-align: center;
}

.image-selector__img {
  height: 150px;
  width: 150px;
  background-color: #FFF3F5;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-selector__img img {
  height: 100px;
  width: 100px;
}

.image-selector__modal {
  width: 500px !important;
  min-width: 500px;
}

.image-selector__modal modal-content {
  padding: 0 !important;
  margin: 0 !important;
}

.image-selector__modal__header {
  width: 100%;
  border-bottom: 1px solid #D9D9DE;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
  padding-left: 20px;
}

.image-selector__modal__header h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
}

.image-selector__modal__content {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.image-selector__modal__images__header {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

.image-selector__modal__images__info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-selector__modal__images__title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;
}

.image-selector__modal__images__title p {
  font-size: 14px;
  font-weight: 500;
}

.image-selector__modal__images__counter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-selector__modal__images__counter p {
  font-size: 14px;
  font-weight: 500;
  color: #AEAEB7;
  padding-left: 4px;
}

.image-selector__modal__images__list {
  padding-top: 4px;
  min-height: 360px;
  display: flex;
  flex-wrap: wrap;
}

.image-selector__modal__images__items {
  width: 100%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}

.image-selector__modal__images__item {
  border: 1px solid transparent;
  margin-top: 10px;
  margin-right: 7px;
  margin-left: 3px;
  width: 56px;
  height: 56px;
}

.image-selector__modal__images__item:hover {
  border: 1px solid #FFF3F5;
  border-radius: 8px;
  width: 56px;
  height: 56px;
  background-color: #FFF3F5;
}

.selected-photo {
  border: 1px solid #FFF3F5;
  border-radius: 8px;
  width: 56px;
  height: 56px;
  background-color: #FFF3F5;
}

.image-selector__modal__images__item img {
  width: 56px;
  height: 56px;
  padding: 7px;
}

.image-selector__modal__images__pagination {
  display: flex;
  width: 100%;
  height: 32px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.image-creator__modal__header {
  width: 100%;
  border-bottom: 1px solid #D9D9DE;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
  padding-left: 20px;
}

.image-creator__modal__header h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
}

.image-creator__modal__content {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.image-creator__modal__upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 16px;
  border: 1px solid #D9D9DE;
}

.image-creator__modal__upload--dragging {
  background-color: #f9f9f9 !important;
  border: 1px solid transparent;
}

.image-creator__modal__upload__title {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #38383E;
}

p {
  margin: 0;
}

.image-creator__modal__upload__description {
  padding-top: 8px;
  color: #7A7A86;
}

.image-creator__modal__buttons {
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
}

.image-creator__modal__button {
  width: 48%;
}

.image-creator__modal__tags {
  padding-top: 24px;
}

.image-creator__modal__tags__title {
  font-size: 16px;
  font-weight: 600;
  color: #38383E;
}

.image-creator__modal__tags__description {
  padding-top: 4px;
  font-size: 14px;
  color: #7A7A86;
}

.image-creator__modal__tags__input {
  padding-top: 20px;
}

.image-creator__modal__tags__items {
  min-height: 100px;
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
}

.image-creator__modal__tags__item {
  padding-left: 15px;
  padding-right: 13px;
  padding-bottom: 4px;
  border-radius: 100px;
  border: 1px solid #D9D9DE;
  height: max-content;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-creator__modal__tags__item p {
  font-size: 14px;
  color: #38383E;
  margin: 0;
}

.image-creator__modal__tags__item__tag {
  padding-top: 2px;
}

.image-creator__modal__tags__item__remove {
  padding-top: 1px;
  padding-left: 6px;
}

.image-creator__modal__uploaded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 16px;
  border: 1px solid #D9D9DE;
}

.image-creator__modal__upload__preview {
  padding-bottom: 24px;
}

.image-creator__modal__upload__preview img {
  padding: 4px;
  width: 108px;
  height: 108px;
  border-radius: 16px;
}

.image-creator__modal__upload__info {
  border: 1px solid #D9D9DE;
  border-radius: 8px;
  width: 94%;
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  font-weight: 500;
  height: 30px;
  padding-top: 4px;
}

.image-creator__modal__upload__info__remove {
  width: 16px;
  height: 16px;
}