
.default-modal__modal {
  width: 500px !important;
  min-width: 500px;
}

.default-modal__modal modal-content {
  padding: 0 !important;
  margin: 0 !important;
}

div.modal-content {
  padding: 0 !important;
  height: auto !important;
  min-width: min-content !important;
  border-radius: 16px;
  border: none;
  min-height: max-content;
}

div.modal-dialog {
  min-width: min-content !important;
}

.default-modal__modal__header {
  width: 100%;
  border-bottom: 1px solid #D9D9DE;
  padding-left: 20px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 10px;
}

.default-modal__modal__header h4 {
  font-size: 14px;
  font-weight: 600;
  padding-top: 4px;
  margin: 0 !important;
}

.default-modal__modal__header p {
  font-size: 14px;
  color: #5F5F68;
}

.default-modal__modal__content {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.default-modal__modal__close {
  padding-top: 2px;
}

.default-modal__modal__close:hover {
  filter: brightness(0.8);
}

p {
  margin: 0;
}

.default-modal__modal__buttons {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.default-modal__modal__button {
  width: 48%;
}

.fade {
  background-color: rgba(122, 122, 134, 0.3);
}