.top-menu {
  height: 71px;
  width: 100%;
  border-bottom: 1px solid #D9D9DE;
  position: fixed;
  background-color: #FEFEFF;
  display: flex;
  align-items: center;
  z-index: 999;
}

.top-menu__left {
  width: 50%;
  display: flex;
  height: 71px;
  display: flex;
  align-items: center;
}

.top-menu__back-screen {
  padding-right: 16px;
}

.top-menu__title {
  color: black;
  display: flex;
}

.top-menu__title h1 {
  font-size: 24px;
  font-weight: 600;
}

.top-menu__title a {
  display: flex;
  color: black;
}

.top-menu__title a:hover {
  color: rgb(100, 100, 100);  
  text-decoration: none;
}

.top-menu__right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 71px;
}

.top-menu__user {
  border-radius: 7px;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
}

.top-menu__user__info {
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  padding-top: 4px;
}

.top-menu__user__info__name {
  font-size: 16px;
  color: #38383E;
  font-weight: 700;
  text-align: right;
}

.top-menu__user__info__role {
  font-size: 16px;
  color: #868693;
  text-align: right;
}

.top-menu__user__icon {
  height: 40px;
  width: 40px;
  background-color: #FFD9DF;
  border-radius: 100px;
  border-right: 1px solid #EBF0FA;
}

.top-menu__user__icon img {
  height: 40px;
  width: 40px;
  border-radius: 100px;
}

.top-menu__notifications {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 16px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-menu__notifications--opened {
  background-color: #F3F3F4;
}

.top-menu__notifications:hover {
  background-color: #F3F3F4;
}

.top-menu__notifications:active {
  background-color: #ececec;
}

.top-menu__logo {
  padding-left: 40px;
  padding-bottom: 3px;
  max-height: max-content;
}

.top-menu__logo img {
  width: 68px;
  height: 22.66px;
}

.top-menu__items {
  height: 71px;
  display: flex;
  justify-content: flex-end;
  padding-right: 64px;
  width: 50%;
}

.top-menu__items__item {
  transition: 0.3s;
  display: flex;
  width: 77px;
  height: 71px;
  color: #4A4A51;
  font-weight: 600;
  border: 1px solid #ffffff;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  border-bottom: 0;
}

.top-menu__items__item:hover {
  transition: 0.3s;
  color: #818181;
}

.top-menu__items__item:last-of-type {
}

.top-menu__items__item--chosen {
  color: #FE8398;
  font-weight: 600;
  border-bottom: 3px solid #FE8398;
}

.top-menu__items__item--chosen:hover {
  color: #ff9dad;
  font-weight: 600;
}

.top-menu__items__item__bar {
  width: 4px;
  margin-left: -1px;
  padding: 0 !important;
  height: 43px;
  background-color: white;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.top-menu__items__item--chosen .left-menu__items__item__bar {
  background-color: #FE8398;
}

.top-menu__items__item__name {
  padding-left: 16px;
  padding-top: 8px;
}

.top-menu__items__item__icon img {
  width: 24px;
  height: 24px;
}

.top-menu__items__item__icon:hover img {
  width: 24px;
  height: 24px;
}

.top-menu__items__item--chosen img {
  width: 24px;
  height: 24px;
}

.top-menu__items__item--chosen:hover img {
  transition: 0.3s;
  opacity: 0.8;
}

.top-menu__shadow {
  height: 68px;
}

.top-menu__additional-options__dropdown .dropdown-menu.show {
  padding-top: 4px;
  padding-bottom: 4px;
  max-width: 113px !important;
}

.top-menu__additional-options__dropdown {
  position: absolute;
  background-color: white;
  z-index: 1;
  border-radius: 10px;
  flex-direction: column;
  margin-left: -162px !important;
  margin-top: 40px !important;
  max-width: 113px !important;
  padding: 0;
}

.top-menu__additional-options__dropdown__item {
  background-color: transparent;
  border: 0;
  width: 100%;
  color: #38383E;
  text-align: left;
  font-size: 14px;
  padding: 0;
  padding-left: 10px;
  padding-top: 13px;
  padding-bottom: 11px;
  border-bottom: 1px solid #F3F3F4;
}

.top-menu__additional-options__dropdown__item:last-of-type {
  border-bottom: 0;
  color: #ED6767;
}

.top-menu__nutritionist__items {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  padding-left: 24px;
}

.top-menu__nutritionist__item {
  color: #344054;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 6px;
  font-weight: 600;
  font-size: 16px;
  max-height: max-content;
}

.top-menu__nutritionist__item:hover {
  color: #FE8398;
}

.top-menu__nutritionist__item--selected {
  color: #FE8398;
  background-color: #FFF3F5;
  font-weight: 600;
  border-radius: 6px;
}

.top-menu__nutritionist__item--selected:hover {
  color: #FE8398;
  background-color: #fdedef;
}

.top-menu__notifications__box {
  position: absolute;
  background-color: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.30);
  z-index: 1;
  border-radius: 12px;
  flex-direction: column;
  margin-top: 484px !important;
  margin-right: 95px;
  width: 313px;
  padding-top: 20px;
  padding-bottom: 8px;
  max-height: 419px;
  overflow: hidden;

  animation: fadeIn 0.1s ease-in;
}

.top-menu__notifications__box--hidden {
  animation: fadeOut 0.150s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.top-menu__notifications__box__header {
  padding-left: 12px;
  padding-right: 12px;
}

.top-menu__notifications__box__title {
  font-size: 16px;
  font-weight: 600;
}


.top-menu__notifications__box__options {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
}

.top-menu__notifications__box__option {
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 20px;
  padding-top: 6px;
  padding-bottom: 4px;
  margin-right: 4px;
  line-height: 12px;
  font-size: 12px;
  color: #868693;
}

.top-menu__notifications__box__option:hover {
  background-color: #f6f6f6;
}

.top-menu__notifications__box__option--selected {
  color: #FE8398;
  font-weight: 600;
  background-color: #FFF3F5;
}

.top-menu__notifications__box__option--selected:hover {
  background-color: #fdedef;
}

.top-menu__notifications__box__items {
  padding-left: 8px;
  padding-right: 8px;
  max-height: 313px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.top-menu__notifications__box__item {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px;
  border-bottom: 1px solid #F3F3F4;
  height: 65px;
}

.top-menu__notifications__box__item:hover {
  background-color: #FFF3F5;
}

.top-menu__notifications__box__item__avatar img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.top-menu__notifications__box__item__content {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  color: #5F5F68;
}

.top-menu__notifications__box__item__content span {
  font-weight: 600;
  color: black;
}

.top-menu__notifications__box__item__time {
  font-size: 10px;
  color: #9E9EA9;
}

.top-menu__notifications__box__item__unread-circle {
  min-width: 7px;
  max-width: 7px;
  min-height: 7px;
  max-height: 7px;
  background-color: #FE8398;
  border-radius: 100%;
}

.top-menu__additional-options .show {
  margin-top: 48px !important;
}

.top-menu__additional-options .dropdown-menu.show {
  box-shadow: 0px 7px 15px rgba(100, 100, 111, 0.2) !important;
}