.label__content {
  min-width: 100%;
  min-height: 12px;
  padding-top: 1px;
}

.label__content p {
  color: gray;
  font-size: 11px;
  margin-bottom: 0;
}

.label {
  margin-bottom: 0 !important;
  font-size: 11px;
  padding-left: 16px;
  padding-top: 2px;
  min-width: 50%;
  text-align: left;
}

.inputSection {
  flex-direction: "row";
  flex-wrap: "wrap";
  border-radius: 8px;
  height: 58px;
  background-color: "white";
  border-color: "#D9D9DE";
  border-width: 1;
  padding-left: 0;
  padding-top: 6px;
  border: 2px #F3F3F4 solid !important;
  background-color: white;
  width: 100%
}

.inputSectionInvalid {
  border-color: #FEACBA !important;
}

.input__invalid-message {
  color: #d78180;
  font-size: 14px;
  padding-top: 8px;
}

.inputSectionDisabled {
  background-color: #F7F7F7;
}

.inputSectionInput {
  padding-top: 2px;
  color: "#000000";
}

.inputSectionInputDisabled {
  display: flex;
  padding-top: 2px;
  color: "#7A7A86";
}

.secureEye {
  padding-top: 1px;
  width: 50px;
  height: 58px;
  align-items: 'flex-end';
  margin-top: -16px !important;
}

.secureEye.btn.btn-primary {
  background-color: "white" !important;
  border-color: "white";
  color: "#BAC8E2";
  padding: 0;
  margin: 0;
  font-size: 12px;
  align-items: "center";
  justify-content: "center";
  display: "flex";
}

.secureEye button {
  background-color: "white";
  border-color: "white";
  color: "#BAC8E2";
  padding: 0;
  margin: 0;
  font-size: 12px;
  align-items: "center";
  justify-content: "center";
  display: "flex";
}

button.secureEye.btn.btn-primary {
  background-color: "white" !important;
}

button .secureEye .btn.btn-primary {
  background-color: "white" !important;
}

.secureEye__icon {
  width: 30px;
  height: 30px;
}

::placeholder {
  color: #868693 !important;
  font-size: 14px;
}

input.form-control {
  color: #000000 !important;
  background-color: transparent !important;
  border: 1px white solid !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  margin-left: 16px !important;
  border-radius: 0 !important;
  display: flex;
  justify-content: center;
  height: 24px !important;
}

input.form-control:focus {
  color: #000000 !important;
  background-color: transparent !important;
  margin-left: 16px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.inputSectionInput button.btn.btn-primary {
  background-color: transparent !important;
  border-color: white;
  border: 0;
  border-radius: 8px !important;
  height: 38px !important;
}

.inputSectionSmall {
  height: 44px;
  padding-top: 11px;
}

.input__description {
  padding-top: 8px;
  color: #868693;
  font-size: 14px;
}