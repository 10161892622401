a {
  text-decoration: none;
}

.container {
  max-width: none !important;
  display: flex;
}

.nutritionist {
  display: flex;
}

.nutritionist__content {
  width: 100%;
  height: 100vh;
  background-color: white;
  padding-left: 112px;
  padding-right: 112px;
}

@media screen and (max-width: 1100px) {
  .nutritionist__content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.nutritionist__content__lists {
  display: flex;
}

.nutritionist__content__header {
  display: flex;
  justify-content: flex-end;
  padding-top: 38px;
  padding-bottom: 3.5px;
}

.nutritionist__content__header h1 {
  font-size: 24px;
  font-weight: 700;
}

.simple-pagination {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.nutritionist__nutritionist__users__footer button:hover {
  border: 0;
  font-size: 12px;
}

.nutritionist__nutritionist__users__footer button:disabled {
  background-color: white;
  border: 0;
  color: #a7a4a4 !important;
  font-size: 12px;
}


.nutritionist__nutritionist__users__footer button:active {
  background-color: rgb(244, 244, 244) !important;
  border: 0;
  color: #818181 !important;
  font-size: 12px;
}

.nutritionist__foodstuffs__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nutritionist__foodstuffs__header h3 {
  display: flex;
  font-size: 16px;
  color: #38383E;
  margin: 0;
  font-weight: 600;
}

.nutritionist__foodstuffs__header__actions button {
  height: 43px;
}

.users__title h3 {
  font-size: 20px;
  padding-top: 12px;
  font-weight: 600;
  height: 43px;
}

.nutritionist__foodstuffs__header__title h3 {
  font-size: 20px;
  padding-top: 4px;
  font-weight: 600;
}

.nutritionist__content__header__title {
  width: 100%;
  padding-top: 8px;
}

.nutritionist__content__header__title h1 {
  font-size: 24px;
}

.nutritionist__content__header__search {
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
}

.nutritionist__content__header__me img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.nutritionist__content__header__invite button {
  height: 40px;
  border-radius: 12px;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
  width: 152px;
}

.nutritionist__users {
  min-height: 420px;
}

.nutritionist__users__item {
  width: 100%;
  height: 60px;
  display: flex;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  color: #000000;
  padding-top: 6px;
  align-items: center;
  border-bottom: 1px solid #F3F3F4;
}

.nutritionist__users__item:hover {
  background-color: #FFFAFB;
}

.nutritionist__users__item__image {
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  background-color: #FFF3F5;
  border-radius: 100%;
}

.nutritionist__users__item__image img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.nutritionist__users__item__name {
  width: 25%;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  display: flex;
  align-items: center;
}

.nutritionist__users__item__name p {
  padding-left: 12px;
  padding-top: 4px;
}

.nutritionist__users__item__birthdate {
  width: 20%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.nutritionist__users__item__brand--not-appliable {
  color: #868693 !important;
}

.nutritionist__users__item__weight {
  width: 25%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.nutritionist__users__item__actions {
  width: 7%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.nutritionist__users__item__actions__edit {
  width: 33%;
  display: flex;
  justify-items: flex-start;
}

.nutritionist__users__item__actions__use-as-draft {
  width: 33%;
}

.nutritionist__users__item__actions__use-as-draft img {
  height: 20px;
}

.nutritionist__users__item__actions button {
  background-color: transparent;
  border: 0;
  /* width: 80%; */
}

.nutritionist__users__item__actions button:hover {
  background-color: #FFF3F5;
  border: 0;
}

.nutritionist__users__item__actions button:active {
  background-color: #fceef0 !important;
  border: 0 !important;
}


.nutritionist__users__item__actions__delete {
  width: 33%;
}

.foodstuffs__search input.form-control {
  height: 38px;
}

.nutritionist__users__footer {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;
  align-items: center;
  height: 94px;
  width: 100%;
}

.simple-pagination {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.nutritionist__users__footer button:hover {
  border: 0;
  font-size: 12px;
}

.nutritionist__users__footer button:disabled {
  background-color: white;
  border: 0;
  color: #a7a4a4 !important;
  font-size: 12px;
}


.nutritionist__users__footer button:active {
  background-color: rgb(244, 244, 244) !important;
  border: 0;
  color: #818181 !important;
  font-size: 12px;
}

.nutritionist__users__item__actions__moreSection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.nutritionist__users__item__actions__moreSection__icon {
  display: flex;
  justify-content: center;
  max-width: 33px;
  max-height: 33px;
}

.nutritionist__users__item__actions__moreSection__icon button {
  width: 100%;
}

.nutritionist__users__item__actions__moreSection__dropdown {
  position: absolute;
  background-color: white;
  max-width: 113px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  border-radius: 10px;
  flex-direction: column;
  margin-left: -162px !important;
  margin-top: 18px;
  max-width: 113px !important;
}

.nutritionist__users__item__actions__moreSection__dropdown__item {
  background-color: transparent;
  border: 0;
  width: 100%;
  color: #38383E;
  text-align: left;
  font-size: 14px;
  padding: 0;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
}

.nutritionist__users__header {
  background-color: #FAFAFA;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 44px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #868693;
  border-bottom: 1px solid #F3F3F4;
}

.nutritionist__users__header__image {
  width: 8%;
  text-align: left;
  padding-left: 20px;
}

.nutritionist__users__header__name {
  width: 25%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.nutritionist__users__header__birthdate {
  width: 20%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.nutritionist__users__header__height {
  width: 25%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.nutritionist__users__header__weight {
  width: 25%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.nutritionist__users__header__actions {
  width: 7%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.nutritionist__users__item--not-appliable {
  color: #868693 !important;
}

.nutritionist__users__item__meal-plan-text {
  padding-left: 4px;
  font-weight: 500;
  color: #027A48;
}

.nutritionist__users__item__meal-plan-dot {}

.nutritionist__users__item__meal-plan-item {
  background: #EDFCF4;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  width: max-content;
  border-radius: 16px;
}

.nutritionist__users__item__meal-plan {
  width: 25%;
  height: 40px;
  padding-top: 6px;
  padding-left: 20px;
  text-align: left;
}

.invite-modal__actions {
  padding-top: 24px;
  display: flex;
}

.invite-modal__button {
  width: 50%;
}

.invite-modal__button button {
  border-radius: 12px;
  height: 43px;
}

.invite-modal__button:first-of-type {
  margin-right: 24px;
}

.patients__empty {
  border: 2px solid #F3F3F4;
  border-radius: 16px;
  padding: 20px;
  margin-top: 16px;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.patients__empty__icon {
  display: flex;
}

.patients__empty__title {
  padding-top: 24px;
}

.patients__empty__title h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.patients__empty__description {
  padding-top: 8px;
  color: #5F5F68;
  font-size: 16px;
  max-width: 588px;
  text-align: center;
}

.patients__empty__button {
  padding-top: 24px;
}

.patients__empty__button button {
  border-radius: 8px;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
}
