a {
  text-decoration: none;
}

.container {
  max-width: none !important;
  display: flex;
}

.appointment {
  display: flex;
  padding-left: 112px;
  padding-right: 112px;
}

.appointment__content {
  width: 100%;
  height: 100vh;
}

.appointment__content__lists {
  display: flex;
}

.appointment__content__header {
  display: flex;
  justify-content: flex-start;
  padding-top: 40px;
  padding-bottom: 20px;
}

.appointment__content__header__previous {
  color: #4A4A51;
  font-size: 14px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
}

.appointment__content__header__previous:hover {
  font-weight: 600;
}

.appointment__content__header__name {
  color: #4A4A51;
  font-weight: 600;
  font-size: 14px;
  background-color: #F3F3F4;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  border-radius: 6px;
}

.appointment__content__header__divisor {
  padding-left: 8px;
  padding-right: 8px;
}

.appointment__content__header__divisor img {
  width: 12px;
  height: 12px;
  margin-top: 2px;
}

.users__title h3 {
  font-size: 20px;
  padding-top: 12px;
  font-weight: 600;
  height: 43px;
}

.patient__content__header__title {
  width: 100%;
  padding-top: 2px;
}

.patient__content__header__title a {
  display: flex;
  text-decoration: none;
  color: #38383E;
}

.patient__content__header__title h1 {
  font-size: 24px;
  padding-left: 24px;
}

.patient__content__header__search {
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
}

.patient__content__header__me img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.patient__content__header__me__avatar {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: #FFD9DF;
}

.patient__content__header__invite {
  margin-right: 24px;
}

.patient__content__header__invite button {
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  width: 144px;
}

.patient__content__header__search .inputSection {
  height: 40px;
  padding: 0;
  width: 375px;
}

.patient__users {
  min-height: 420px;
}

.patient__users__item {
  width: 100%;
  height: 60px;
  display: flex;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  color: #000000;
  padding-top: 6px;
  align-items: center;
  border-bottom: 1px solid #F3F3F4;
}

.patient__users__item:hover {
  background-color: #FFFAFB;
}

.patient__users__item__image {
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  background-color: #FFF3F5;
  border-radius: 100%;
}

.patient__users__item__image img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.patient__users__item__name {
  width: 25%;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  display: flex;
  align-items: center;
}

.patient__users__item__name p {
  padding-left: 12px;
  padding-top: 4px;
}

.patient__users__item__birthdate {
  width: 18%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;

}

.patient__users__item__measurement {
  width: 15%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.patient__users__item__height {
  width: 15%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.patient__users__item__brand--not-appliable {
  color: #868693 !important;
}

.patient__users__item__weight {
  width: 20%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.patient__users__item__actions {
  width: 7%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.patient__users__item__actions__edit {
  width: 33%;
  display: flex;
  justify-items: flex-start;
}

.patient__users__item__actions__use-as-draft {
  width: 33%;
}

.patient__users__item__actions__use-as-draft img {
  height: 20px;
}

.patient__users__item__actions button {
  background-color: transparent;
  border: 0;
  /* width: 80%; */
}

.patient__users__item__actions button:hover {
  background-color: #FFF3F5;
  border: 0;
}

.patient__users__item__actions button:active {
  background-color: #fceef0 !important;
  border: 0 !important;
}


.patient__users__item__actions__delete {
  width: 33%;
}

.foodstuffs__search input.form-control {
  height: 38px;
}

.patient__users__footer {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;
  align-items: center;
  height: 94px;
  width: 100%;
}

.simple-pagination {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.patient__users__footer button:hover {
  border: 0;
  font-size: 12px;
}

.patient__users__footer button:disabled {
  background-color: white;
  border: 0;
  color: #a7a4a4 !important;
  font-size: 12px;
}


.patient__users__footer button:active {
  background-color: rgb(244, 244, 244) !important;
  border: 0;
  color: #818181 !important;
  font-size: 12px;
}

.patient__users__item__actions__moreSection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.patient__users__item__actions__moreSection__icon {
  display: flex;
  justify-content: center;
  max-width: 33px;
  max-height: 33px;
}

.patient__users__item__actions__moreSection__icon button {
  width: 100%;
}

.patient__users__item__actions__moreSection__dropdown {
  position: absolute;
  background-color: white;
  max-width: 113px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  border-radius: 10px;
  flex-direction: column;
  margin-left: -162px !important;
  margin-top: 18px;
  max-width: 113px !important;
}

.patient__users__item__actions__moreSection__dropdown__item {
  background-color: transparent;
  border: 0;
  width: 100%;
  color: #38383E;
  text-align: left;
  font-size: 14px;
  padding: 0;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
}

.patient__users__header {
  background-color: #FAFAFA;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 44px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #868693;
  border-bottom: 1px solid #F3F3F4;
}

.patient__users__header__image {
  width: 8%;
  text-align: left;
  padding-left: 20px;
}

.patient__users__header__name {
  width: 25%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__header__birthdate {
  width: 18%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__header__measurement {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__header__height {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__header__weight {
  width: 20%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__header__actions {
  width: 7%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__item--not-appliable {
  color: #868693 !important;
}

.appointment__patient {
  width: 50%;
  margin-right: 40px;
  max-height: 638px;
  border-radius: 24px;
  border: 2px solid #F3F3F4;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.01);
  padding: 24px;
}

.patient__details__name {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.patient__details__name__avatar {
  width: 151px;
  height: 151px;
  background-color: #FFF3F5;
  border-radius: 100%;
}

.patient__details__name__avatar img {
  width: 151px;
  height: 151px;
  border-radius: 100%;
}

.patient__details__name h2 {
  font-size: 18px;
  padding-top: 14px;
  font-weight: 600;
}

.patient__details__actions {
  padding-top: 20px;
  padding-bottom: 8px;
  border-bottom: 2px solid #F3F3F4;
}

.patient__details__actions button {
  border-radius: 8px;
}

.patient__details__actions button:first-of-type {
  margin-bottom: 12px;
}

.patient__details__personal {
  padding-top: 22px;
}

.patient__details__personal__title h3 {
  font-size: 16px;
  font-weight: 600;
}

.patient__details__personal__forms {
  padding-top: 18px;
}

.patient__details__personal__forms__measurements {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
}

.patient__details__personal__forms__measurements .inputSection {
  width: 48%;
}

.appointment__container {
  display: flex;
}

.appointment__details {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 638px;
  border-radius: 24px;
  border: 2px solid #F3F3F4;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.01);
  padding: 24px;
}

.appointment__details__content {
  min-height: 50%;
}

.appointment__details__title {
  padding-bottom: 20px;
}

.appointment__details__title h3 {
  font-size: 16px;
  font-weight: 600;
}

.appointment__details__when {
}

.appointment__details__when__title h4 {
  font-size: 14px;
  color: #5F5F68;
  margin: 0;
}

.appointment__details__when__date {
  font-size: 14px;
  font-weight: 600;
  padding-top: 4px;
}

.appointment__details__time {
  padding-top: 16px;
}

.appointment__details__time__title h4 {
  font-size: 14px;
  color: #5F5F68;
  margin: 0;
}

.appointment__details__time__hour {
  font-size: 14px;
  font-weight: 600;
  padding-top: 4px;
}

.appointment__details__where {
  padding-top: 16px;
}

.appointment__details__where__title h4 {
  font-size: 14px;
  color: #5F5F68;
  margin: 0;
}

.appointment__details__where__address {
  font-size: 14px;
  font-weight: 600;
  padding-top: 4px;
}

.appointment__details__actions {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}

.appointment__details__actions button {
  border-radius: 8px;
}

.appointment__details__actions__cancel {
  width: 50%;
  margin-right: 12px;
}

.appointment__details__actions__reschedule {
  width: 50%;
}

.appointment__details__status {
  padding-top: 16px;
}

.appointment__details__status__title h4 {
  font-size: 14px;
  color: #5F5F68;
  margin: 0;
}

.appointment__details__status__text {
  font-size: 14px;
  font-weight: 600;
  padding-top: 4px;
}

.reschedule-modal__periods {
  display: flex;
  width: 100%;
  padding-top: 20px;
}

.reschedule-modal__time {
  width: 50%;
}

.manage-availability-modal__content .dropdown-toggle {
  min-height: 58px;
}

.reschedule-modal__time-divisor {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 22px;
}

.reschedule-modal__time-divisor p {
  font-size: 12px;
}
