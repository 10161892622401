.avatar-selector-modal__header {
  display: flex;
  padding-top: 24px;
}

.avatar-selector-modal__info {
  width: 50%;
  display: flex;
  padding-top: 10px;
}

.avatar-selector-modal__info p {
  font-size: 14px;
}

.avatar-selector-modal__title {
  font-weight: 600;
}

.avatar-selector-modal__amount {
  color: #AEAEB7;
  padding-left: 6px;
  padding-top: 0.6px;
}

.avatar-selector-modal__button {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.avatar-selector-modal__button button {
  border-radius: 8px;
  min-width: max-content;
}

.avatar-selector-modal__button button:hover {
  border-radius: 8px;
  min-width: max-content;
}

.avatar-selector-modal__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 24px;
  width: 452px;
  margin: 0 auto;
}

.avatar-selector-modal__item {
  height: 98px;
  width: 98px;
  background-color: #FFF3F5;
  border-radius: 20px;
  margin-bottom: 24px;
}

.avatar-selector-modal__item:hover {
  background-color: #ffdbe1;
}

.avatar-selector-modal__item img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.modal-dialog .modal-content {
  min-width: min-content;
}

.avatar-selector-modal__footer {
  padding-top: 4px;
}