
.create-brand__modal {
  width: 500px !important;
  min-width: 500px;
}

.create-brand__modal modal-content {
  padding: 0 !important;
  margin: 0 !important;
}

.create-brand__modal__header {
  width: 100%;
  border-bottom: 1px solid #D9D9DE;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
  padding-left: 20px;
}

.create-brand__modal__header h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
}

.create-brand__modal__content {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  height: 279px;
}

p {
  margin: 0;
}

.create-brand__modal__buttons {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 195px;
}

.create-brand__modal__button {
  width: 48%;
}