a {
  text-decoration: none;
}

.login {
  font-size: 12px;
  color: black;
  height: 100vh;
}

.container {
  max-width: none !important;
  display: flex;
}

.home {
  padding-left: 130px;
  padding-right: 130px;
  display: flex;
  justify-content: center;
}

.row {
  width: 100% !important;
}

.home__content {
  width: 100%;
  max-width: 1300px;
  height: 100vh;
  background-color: white;
  padding-top: 40px;
  margin-bottom: 40px;
}

.home__content__lists {
  display: flex;
  margin-top: 24px;
}

.home__content__header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
}

.home__content__header h1 {
  font-size: 24px;
  font-weight: 700;
}

.right-side__content__metrics {
  display: flex;
}

.right-side__content__container {
  background-color: white;
  padding-left: 15px;
  margin-top: 15px;
  border: 1px solid #D9D9DE;
  border-radius: 16px;
  width: 100%;
  min-height: 400px;
}

.right-side__content__metrics__item {
  width: 100%;
  height: 106px;
  border-radius: 16px;
  background-color: #FFF3F5;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  margin-right: 20px;
  display: flex;
}

.right-side__content__metrics__item__icon {
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-side__content__metrics__item__icon img {
  width: 32px;
  height: 32px;
}

.right-side__content__metrics__item__info {
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 8px;
}

.content__left {
  width: 66%;
}

@media (max-width: 768px) {
  .right-side__content__metrics__item {}
}

.right-side__content__metrics__item:last-child {
  margin-right: 0;
}

.right-side__content__metrics__item__title {
  padding-top: 8px;
  font-size: 16px;
  text-align: left;
  color: #868693;
}

.right-side__content__metrics__item__value {
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  color: #38383E;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

.exit-button {
  background-color: transparent;
  border: 0;
  color: #818181;
  display: flex;
  width: 100%;
  height: 43px;
  border-radius: 12px;
  padding-top: 9px;
}

.exit-button:hover {
  background-color: rgb(246, 246, 246);
  color: #818181;
  transition: 0.7s;
}

.exit-button img {
  padding-left: 12px;
  padding-top: 5px;
}

.exit-button__text {
  color: #818181;
  padding-left: 8px;
}

.exit-button:active {
  outline: none;
  background-color: #f4f4f4;
}

.exit-button:focus {
  outline: none;
  background-color: rgb(212, 212, 212) !important;
}

.right-side__content__lists {
  padding-left: 22px;
  display: block;
  width: 34%;
}

.right-side__content__lists__users {
  max-width: 100% !important;
  background-color: white;
  border-radius: 16px;
  border: 2px solid #F3F3F4;
  font-size: 12px;
  padding: 20px;
}

.right-side__content__lists__users__search {
  display: flex;
}

.users__items {
  padding-top: 13px;
  min-height: 533px;
}

.users__item {
  display: flex;
  width: 100%;
  border-radius: 12px;
  color: #818181;
  margin-bottom: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.users__item__avatar {
  margin-top: 5px;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  background-color: #FFF3F5;
}

.users__item__avatar img {
  height: 40px;
  width: 40px;
  border-radius: 100px;
}

.users__item__info {
  padding-left: 10px;
  padding-top: 5px;
}

.users__item__name {
  font-size: 14px;
  color: #5F5F68;
}

.users__item__role {
  padding-top: 0px;
  font-size: 12px;
  color: #9E9EA9;
}

.users__footer {
  display: flex;
  justify-content: center;
  padding-right: 0;
  padding-top: 15px;
}

.content__left__foodstuffs {
  border: 2px solid #F3F3F4;
  padding: 20px;
  border-radius: 16px;
}

.home__foodstuffs__list {
  padding: 0;
  background-color: blue;
  min-height: 560px;
  background-color: white;
  margin-top: 20px;
  border-radius: 16px;
  border: 1px solid #F3F3F4;
}

.home__foodstuffs__list__header {
  background-color: #FAFAFA;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 44px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 12px;
  color: #868693;
  font-weight: 500;
  border-bottom: 1px solid #FAFAFA;
}

.home__foodstuffs__list__header__image {
  width: 10%;
  text-align: left;
  padding-left: 20px;
}

.home__foodstuffs__list__header__name {
  width: 40%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.home__foodstuffs__list__header__type {
  width: 20%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.home__foodstuffs__list__header__actions {
  width: 30%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.home__foodstuffs__list__item {
  width: 100%;
  height: 60px;
  display: flex;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  color: #000000;
  align-items: center;
  padding-top: 6px;
  border-bottom: 1px solid #F3F3F4;
}

.home__foodstuffs__list__item:hover {
  background-color: #FFFAFB;
}

.home__foodstuffs__list__item__image {
  width: 10%;
  height: 40px;
  align-items: center;
  display: flex;
  padding-left: 21px;
  padding-right: 20px;
}

.home__foodstuffs__list__item__image img {
  height: 32px;
  width: 32px;
}

.home__foodstuffs__list__item__name {
  width: 40%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.home__foodstuffs__list__item__type {
  width: 20%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.home__foodstuffs__list__item__measurement {
  width: 20%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.home__foodstuffs__list__item__brand {
  width: 20%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.home__foodstuffs__list__item__brand--not-appliable {
  color: #D5D5D5;
}

.home__foodstuffs__list__item__actions {
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 24px;
  text-align: left;
}

.home__foodstuffs__list__item__actions__edit {
  display: flex;
  justify-items: flex-end;
  width: 100%;
  justify-content: flex-end;
  padding: 0;
  padding-bottom: 4px;
}

.home__foodstuffs__list__item__actions__edit button.btn.btn-primary {
  background-color: transparent;
  border: 0;
  width: 30px;
}

.home__foodstuffs__list__item__actions__edit img {
  display: flex;
  opacity: 0.6;
  justify-content: flex-end;
}

.home__foodstuffs__list__item__actions button {
  background-color: transparent;
  border: 0;
  width: 80%;
}

.home__foodstuffs__list__item__actions button:hover {
  background-color: rgb(245, 245, 245);
  border: 0;
}

.home__foodstuffs__list__item__actions button:active {
  background-color: rgb(233, 233, 233) !important;
  border: 0 !important;
}

.home__foodstuffs__list__footer {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;
  width: 100%;
}

.simple-pagination {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.home__foodstuffs__list__footer button:hover {
  border: 0;
  font-size: 12px;
}

.home__foodstuffs__list__footer button:disabled {
  background-color: white;
  border: 0;
  color: #a7a4a4 !important;
  font-size: 12px;
}


.home__foodstuffs__list__footer button:active {
  background-color: rgb(244, 244, 244) !important;
  border: 0;
  color: #818181 !important;
  font-size: 12px;
}

.home__foodstuffs__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home__foodstuffs__header h3 {
  display: flex;
  font-size: 16px;
  color: #38383E;
  margin: 0;
  font-weight: 600;
}

.home__foodstuffs__header__actions button {
  height: 43px;
}

.users__title h3 {
  font-size: 20px;
  padding-top: 12px;
  font-weight: 600;
  height: 43px;
}

.home__foodstuffs__header__title h3 {
  font-size: 20px;
  padding-top: 4px;
  font-weight: 600;
}

@media (max-width: 700px) {
  .left-side {
    display: none;
  }
}

@media (max-width: 1068px) {
  .right-side__content__lists {
    display: none;
  }

  .content__left {
    width: 100%;
  }

  .home {
    padding-left: 40px;
    padding-right: 40px;
  }
}