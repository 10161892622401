.breadcrumb {
  display: flex;
  justify-content: flex-start;
  padding-top: 40px;
  padding-bottom: 20px;
  margin: 0;
}

.breadcrumb__previous {
  color: #4A4A51;
  font-size: 14px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
}

.breadcrumb__previous:hover {
  font-weight: 600;
}

.breadcrumb__name {
  color: #4A4A51;
  font-weight: 600;
  font-size: 14px;
  background-color: #F3F3F4;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  border-radius: 6px;
}

.breadcrumb__divisor {
  padding-left: 8px;
  padding-right: 8px;
}

.breadcrumb__divisor img {
  width: 12px;
  height: 12px;
  margin-top: 2px;
}