.nutritionist-profile {
  width: 100%;
  height: 100vh;
  padding-left: 112px;
  padding-right: 112px;
}

.nutritionist-profile__title h1 {
  font-size: 24px;
  font-weight: 600;
}

.nutritionist-profile__content {
  display: flex;
  padding-top: 20px;
}

.nutritionist-profile__personal-info {
  width: 55%;
}

.nutritionist-profile__preview {
  width: 45%;
  display: flex;
  flex-direction: column;
  padding-left: 64px;
}

.nutritionist-profile__preview__title h2 {
  font-size: 20px;
  font-weight: 600;
}

.editable-field {
  display: flex;
  padding-top: 16px;
  padding-bottom: 14px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #F3F3F4;
}

.editable-field a {
  text-decoration: none;
  color: #4A4A51;
  font-weight: 600;
}

.editable-field a:hover {
  color: #838383;
}

.editable-field p {
  line-height: 22px;
}

.editable-field__label {
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.editable-field__value {
  padding-top: 4px;
  color: #7A7A86;
}

.editable-field__value input {
  border: 0;
  color: #7A7A86;
}

.editable-field__value input:disabled {
  background-color: white;
}

.editable-field__value input:disabled {
  background-color: white;
}

.editable-field__value input:focus {
  outline: none;
  border: 0;
  box-shadow: none;
}

.editable-field__edit {
  padding-top: 6px;
}

.editable-field__tooltip {
  padding-left: 6px;
}

.editable-field__tooltip img {
  margin-bottom: 3px;
  height: 16px;
  width: 16px;
}

.editable-field__tooltip__content {
  position: absolute;
  background-color: #262626;
  color: white;
  border-radius: 8px;
  padding: 12px;
  padding-bottom: 11px;
  z-index: 1;
  max-width: 280px;
  display: flex;
  margin-left: -5px;
}

.editable-field__tooltip__close {
  padding-left: 8px;
}

.editable-field__tooltip__close img {
  filter: brightness(0) invert(1);
}

.editable-field__tooltip__close img:hover {
  filter: brightness(0.2) invert(1);
}

.editable-field__tooltip__content p {
  font-weight: 100;
  font-size: 14px;
  margin: 0;
}

.nutritionist-profile__personal-info__warning {
  padding-top: 24px;
  display: flex;
  align-items: center;
}

.nutritionist-profile__personal-info__warning img {
  height: 20px;
  width: 20px;
}

.nutritionist-profile__personal-info__warning p {
  padding-left: 10px;
  font-size: 14px;
  color: #4A4A51;
}

.nutritionist-profile__preview__content {
  border-radius: 20px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.10);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 16px;
}

.nutritionist-profile__preview__content__avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nutritionist-profile__preview__content__avatar__edit {
  padding-top: 8px;
}

.nutritionist-profile__preview__content__avatar__edit a {
  font-size: 14px;
  font-weight: 600;
  color: #FE8398
}

.nutritionist-profile__preview__content__name {
  padding-top: 20px;
  font-size: 24px;
  font-weight: 600;
  color: #38383E;
  line-height: 33px;
}

.nutritionist-profile__preview__content__specializations {
  font-size: 16px;
  color: #7A7A86;
  line-height: 22px;
}

.nutritionist-profile__preview__content__metrics {
  display: flex;
  padding-top: 20px;
}

.nutritionist-profile__preview__content__metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 22.75px;
  padding-right: 22.75px;
  border-right: 1px solid #D9D9DE;
}

.nutritionist-profile__preview__content__value {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.nutritionist-profile__preview__content__value img {
  padding-left: 3px;
  padding-bottom: 3px;
}

.nutritionist-profile__preview__content__label {
  padding-top: 4px;
  font-size: 14px;
  color: #5F5F68;
  line-height: 19px;
}

.nutritionist-profile__preview__content__addables {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 10px;
}

.addable-field {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.addable-field__info {
  width: 100%;
}

.addable-field__label {
  font-size: 14px;
  font-weight: 600;
  color: #38383E;
}

.addable-field__value {
  font-size: 14px;
  color: #7A7A86;
  padding-top: 2px;
  min-width: 100%;
}

.addable-field__value input {
  padding: 0;
  border: 0;
  color: #7A7A86;
  width: 100%;
}

.addable-field__value textarea {
  padding: 0;
  border: 0;
  color: #7A7A86;
  width: 100%;
  min-height: min-content;
  scrollbar-width: none;
  resize: none;

  /* Please, add below a property to allow this textarea to have a min height according to the inside content */

  min-height: 0px;
}

.addable-field__value textarea::placeholder {
  color: #7A7A86;
}

.addable-field__value input::placeholder {
  color: #7A7A86;
}

.addable-field__value input:focus {
  outline: none;
  border: 0;
  box-shadow: none;
}

.addable-field__value textarea:focus {
  outline: none;
  border: 0;
  box-shadow: none;
}

.addable-field__value input:disabled {
  background-color: white;
}

.addable-field__value textarea:disabled {
  background-color: white;
}

.addable-field__action a {
  color: #FE8398;
  font-size: 14px;
  font-weight: 600;
}

.addable-field__action a:hover {
  color: #ee7085;
}

.nutritionist-profile__suggestion {
  margin-top: 16px;
  border-radius: 12px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.10);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.nutritionist-profile__suggestion__title {
  font-size: 14px;
  font-weight: 600;
  color: #38383E;
}

.nutritionist-profile__suggestion__description {
  font-size: 14px;
  color: #7A7A86;
  padding-top: 4px;
}

.nutritionist-profile__suggestion__actions {
  display: flex;
  padding-top: 20px;
  min-width: 100%;
}

.nutritionist-profile__suggestion__actions__button {
  margin-right: 12px;
}

.nutritionist-profile__suggestion__actions button {
  border-radius: 12px;
}