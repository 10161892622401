p {
  margin: 0;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

.landing__privacy {
  padding: 112px;
  padding-top: 60px;
  padding-bottom: 162px;
}

.landing__privacy__title {
  font-size: 40px;
  font-weight: 600;
}

.landing__privacy__title h1 {
  font-size: 40px;
  font-weight: 600;
}

.landing__privacy__description {
  padding-top: 16px;
  font-size: 20px;
  color: #7A7A86;
}

.landing__privacy__updatedAt {
  padding-top: 20px;
  font-size: 16px;
  color: #AEAEB7;
}

.landing__privacy__content {
  display: flex;
  padding-top: 72px;
  justify-content: space-between;
}

.landing__privacy__paragraphs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing__privacy__paragraph:first-child {
  padding-top: 0;
}

.landing__privacy__paragraph {
  padding-top: 68px;
}

.landing__privacy__paragraph h3 {
  font-size: 24px;
  font-weight: 600;
}

.landing__privacy__paragraph p {
  padding-top: 12px;
  font-size: 16px;
  color: #5F5F68;
}

.landing__privacy__bulletPoints {
  padding-top: 20px;
  color: #5F5F68;
}

.landing__privacy__bulletPoints ul li {
  padding-top: 2px;
}

.landing__footer {
  border-top: 1px solid #D9D9DE;
  display: flex;
  justify-content: space-between;
  padding-left: 112px;
  padding-right: 112px;
  padding-top: 28px;
  padding-bottom: 24px;
  color: #7A7A86;
}

.landing__footer__copyright {
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.landing__footer img {
  height: 26px;
  width: 78px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 32px !important;
  }

  .landing__privacy {
    padding: 60px 24px;
  }

  .landing__privacy__description {
    font-size: 20px;
  }

  .landing__privacy__paragraphs {
    padding-top: 48px;
  }

  .landing__privacy__paragraph {
    padding-top: 54px;
  }

  .landing__privacy__paragraph h3 {
    font-size: 20px;
  }

  .landing__privacy__paragraph p {
    font-size: 16px;
  }

  .landing__footer {
    margin-top: 0;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: center;
  }

  .landing__footer img {
    display: none;
  }
  
  button {
    max-height: 46px;
    margin-top: 2px;
  }
}