.bottom-notification {
  /* height: 100vh; */
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
}

.bottom-notification__section::before {
  transform: translateY(100px);
}

.bottom-notification__section {
  background-color: #38383E;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 12px;
  display: flex;
  height: max-content;
  margin-bottom: 32px;
  max-width: 80%;

  animation: slideInFromBottom 1s ease;

  /* -webkit-animation: slideInFromBottom 10s ease; */
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(150px);
  }

  100% {
    transform: translateY(0);
  }
}

.bottom-notification__section--hidden {
  animation: slideOutToBottom 1s ease;
}

@keyframes slideOutToBottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(150px);
  }
}

.bottom-notification__section img {
  height: max-content;
}

.bottom-notification__section p {
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding-left: 16px;
  padding-top: 3px;
  line-height: 20px;
}
