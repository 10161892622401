a {
  text-decoration: none;
}

.right-side {
  width: 100%;
}

.new-foodstuff {
  width: 100%;
  max-width: 1300px;
  padding: 0;
}

.new-foodstuff__content {
  width: 100%;
  min-height: 600px;
  padding-top: 24px;
  background-color: white;
  border: 0 !important;
  border-radius: 40px;
}

.new-foodstuff__content h1 {
  font-size: 26px;
  color: #8C8989;
}

.new-foodstuff__create-button {
  border: 0;
  color: white;
  border-radius: 9px;
  padding-left: 10px;
  font-size: 12px;
  width: 200px;
  float: right;
}

.new-foodstuff__basic-information {
  padding-top: 15px;
}

.new-foodstuff__basic-information__title {}

.new-foodstuff__basic-information__title h3 {
  font-size: 20px;
  color: #38383E;
  padding-left: 2px;
  padding-bottom: 16px;
  margin: 0 !important;
  font-weight: 600;
}

.new-foodstuff__basic-information__content {
  background-color: white;
  border: 1px solid #D9D9DE;
  border-radius: 16px;
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
}

.new-foodstuff__basic-information__content__select-image {
  display: flex;
  width: 13%;
}

.new-foodstuff__basic-info__content__name {
  width: 100%;
}

.new-foodstuff__basic-info__content__measurement {
  width: 50%;
}

form-label {
  margin-bottom: -30px !important;
  font-size: 12px;
  color: #868693;
}

.new-foodstuff__basic-info__content__group {
  width: 50%;
  padding-right: 20px;
}

.new-foodstuff__footer {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 100px !important;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.new-foodstuff__basic-info__first-line {
  width: 100%;
}

.new-foodstuff__basic-info__right {
  width: 87%;
  padding-left: 5px;
  display: block;
}

.new-foodstuff__basic-info__right__first-line {
  display: flex;
}

.new-foodstuff__basic-info__right__second-line {
  display: flex;
}

.new-foodstuff__basic-info__right__third-line {
  display: flex;
  width: 100%;
}

.new-foodstuff__basic-info__weight {
  width: 34%;
  padding-right: 20px;
}

.new-foodstuff__basic-info__barcode {
  width: 33%;
  padding-right: 20px;
}

.new-foodstuff__basic-info__brand {
  width: 33%;
}

.nutritional-info__title h3 {
  font-size: 20px;
  color: #38383E;
  padding-left: 2px;
  padding-bottom: 16px;
  margin: 0 !important;
  font-weight: 600;
}

.nutritional-info__content {
  background-color: white;
  border-radius: 16px;
  padding-top: 4px;
  display: flex;
  flex-wrap: wrap;
}

.nutritional-info__content__calories {
  width: 50%;
  padding-bottom: 20px;
}

.nutritional-info__content__proteins {
  padding-left: 20px;
  width: 50%;
}

.nutritional-info__content__carbohydrates {
  width: 25%;
}

.nutritional-info__content__sodium {
  padding-left: 20px;
  width: 25%;
}

.nutritional-info__content__fibers {
  padding-left: 20px;
  width: 25%;
}

.nutritional-info__content__saturated-fats {
  padding-left: 20px;
  width: 25%;
}

.dropdown-toggle {
  background-color: #ffffff !important;
  border-radius: 9px;
  height: 58px;
  width: 100%;
  color: #000000;
  border: 1px solid #D9D9DE;
  text-align: left;
  font-size: 14px;
  padding-top: 10px;
}

.dropdown-toggle.show {
  color: #000000;
  border: 1px solid #D9D9DE;
}

.create-foodstuff {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  padding-left: 130px;
  padding-right: 130px;
}

.top-menu__title {
  margin-top: 40px;
}

.new-foodstuff__basic-information {
  padding-top: 0 !important;
}

.dropdown-toggle.btn.btn-success::after {
  float: right;
  margin-top: 9px;
  margin-right: 10px;
  font-size: 15px;
}

.dropdown-toggle.btn.btn-success:hover {
  color: #000000;
  border: 1px solid #D9D9DE;
}

.dropdown-menu.show {
  width: 100%;
  background-color: #ffffff !important;
  border: 0;
  border-radius: 12px;
  margin-top: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  color: #000000;
  min-height: 38px;
  max-width: 100%;
}

.dropdown-item:hover {
  background-color: #f8f8f8;
  color: #595959;
}

.new-foodstuff__brand__searchSection {
  padding-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 4px;
  display: flex;
  min-width: 100%;
}

.new-foodstuff__brand__searchSection__button {}

.new-foodstuff__brand__searchSection__button button {
  width: 40px;
  border-radius: 8px;
}

.new-foodstuff__brand__searchSection__input {
  /* padding-right: 8px; */
  width: 100%;
}

.dropdown__title {
  color: #9E9EA9;
}

.dropdown__title:hover {
  color: #93939f !important;
}

.dropdown__title--selected {
  color: #000000;
}

.dropdown__title--selected:hover {
  color: #000000;
}