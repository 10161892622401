.registration {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 64px;
    padding-right: 64px;
}

.registration__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.registration__header__button {
    padding-top: 3px;
}

.registration__header__button button {
    padding: 0;
    color: #FE8398;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

.registration__header__logo img {
    height: 42px;
    width: 123px;
}

.steps {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
}

.steps__step {
    width: 80px;
    height: 6px;
    border-radius: 4px;
    background-color: #D9D9DE;
    margin-right: 12px;
}

.steps__step:last-of-type {
    margin-right: 0;
}

.steps__step--active {
    width: 80px;
    height: 6px;
    border-radius: 4px;
    background-color: #FE8398;
    margin-right: 12px;
}

.registration__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 78px;
}

.default-step {
    max-width: 452px;
}

.default-step__title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}

.default-step__title__subtitle {
    text-align: center;
    color: #7A7A86;
    font-size: 18px;
    padding-top: 4px;
}

.default-step__form {
    padding-top: 24px;
}

.default-step__button {
    padding-top: 24px;
}

.default-step__footer {
    padding-top: 24px;
    font-size: 14px;
    text-align: center;
}

.default-step__footer p {
    color: #868693;
}

.default-step__footer a {
    color: #FE8398;
    text-decoration: none;
    font-weight: 600;
}

.inscription-step__form--crn {
    padding-top: 20px;
}

.inscription-step__error {
    margin-top: 24px;
    background-color: #FFEDED;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: row;
}

.inscription-step__error__message {
    padding-left: 12px;
    color: #ED6767;
    font-weight: 600;
    font-size: 14px;
}

.verification-step__form {
    padding-top: 24px;
    max-width: 400px;
    margin: 0 auto;
}

.verification-step__form input {
    border: none;
    font-size: 40px;
    letter-spacing: 76px;
    padding-left: 30px;
}

.verification-step__form input:focus {
    border: none !important;
    outline: none;
}

.verification-step__dashes {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.verification-step__dash {
    width: 91px;
    height: 2px;
    background-color: #7A7A86;
    margin-right: 12px;
}

.verification-step__resend {
    padding-top: 6px;
}

.default-step__divisor {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 2px solid #F3F3F4;
}

.next-steps {
    border: 2px solid #F3F3F4;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
}

.next-steps__title {
    font-size: 18px;
    font-weight: 600;
}

.next-steps__subtitle {
    color: #868693;
    font-size: 16px;
    padding-top: 4px;
}

.next-steps button {
    margin-top: 20px;
}

.registration-success__icon {
    text-align: center;
    /* padding-top: 40px; */
}

.registration-success__title {
    padding-top: 24px;
}

.verification-step__timer {
    padding-top: 24px;
    text-align: center;
    font-size: 14px;
    color: #868693;
}