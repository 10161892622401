div.modal-content {
  border-radius: 20px;
  min-height: 420px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  margin: 0 auto;
  margin-top: 150px;
}

div.modal-header {
  border: 0;
  display: block;
  text-align: center;
  height: 180px;
  padding-top: 80px;
}

div.modal-header h4 {
  font-size: 20px;
  color: #6c6767;
}

div.modal-body {
  min-height: 100px;
}

div.modal-footer {
  border: 0;
  display: flex;
  align-content: center;
  min-height: 100px;
}

.modal__error-icon {
  max-height: 50px;
  align-self: center;
  border: 7px solid #f38174;
  border-radius: 100px;
}

.modal__success-icon img {
  height: 50px;
}

.modal__title {
  padding-top: 20px;
}

.modal__description {
  color: #c0c0c0;
  font-size: 13px;
  text-align: center;
}

.modal__actions {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 320px;
}

.modal__actions button {
  width: 320px;
  height: 45px;
  font-weight: 500;
}

.modal__actions__confirm {
  width: 100% !important;
  display: flex;
  align-items: center !important;
  align-content: center;
}

.error-modal__actions__confirm button {
  background-color: #eb796c;
  border: 1px solid #e57366;
}

.error-modal__actions__confirm button:hover {
  background-color: #e57265;
  border: 1px solid #dd6b5e;
}

.error-modal__actions__confirm button:active {
  background-color: #e06c5f !important;
  border: 1px solid #d76558 !important;
}

.modal__actions__cancel {
  width: 100%;
  display: flex;
  align-items: center !important;
  padding-top: 10px;
}

.modal__actions__cancel button {
  background-color: white;
  color: #726c6c;
  border: 0;
}

.modal__actions__cancel button:hover {
  background-color: rgb(244, 244, 244);
  color: #726c6c;
  border: 0;
}

.modal__actions__cancel button:active {
  background-color: rgb(231, 231, 231) !important;
  color: #726c6c !important;
  border: 0;
}

.error-modal__title {
  padding-top: 24px;
  width: 100%;
  text-align: center;
}

.error-modal__title h4 {
  font-size: 24px;
  font-weight: 600;
  color: #38383E;
  margin: 0;
}

.error-modal__description {
  text-align: center;
  padding-top: 4px;
  color: #7A7A86;
}

.error-modal__actions {
  width: 100%;
  padding-top: 24px;
}

.error-modal__button {
  padding-top: 16px;
}