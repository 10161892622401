a {
  text-decoration: none;
}

.right-side__content {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
}

.new-foodstuff {
  width: 100%;
}

.update-foodstuff {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
}

.new-foodstuff__content {
  width: 100%;
  min-height: 600px;
  padding-top: 40px;
  background-color: white;
  border: 2px solid #EBF0FA;
  border-radius: 17px;
}

.new-foodstuff__content h1 {
  font-size: 26px;
  color: #8C8989;
}

.new-foodstuff__basic-information {
  padding-top: 15px;
}

.new-foodstuff__basic-information__title h3 {
  font-size: 14px;
  color: #B9B9B9;
  font-weight: 400;
  padding-left: 15px;
}

.new-foodstuff__basic-information__content {
  background-color: white;
  border: 1px solid #e5e8ee;
  border-radius: 17px;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.new-foodstuff__basic-information__content__select-image {
  display: flex;
  width: 15%;
}

.new-foodstuff__basic-information__content__select-image__image {
  height: 150px;
  width: 150px;
  background-color: #F5F7FB;
  border-radius: 11px;
  border: 1px #dce3f0 solid !important;
}

.new-foodstuff__basic-information__content__select-image__image p {
  color: #B9B9B9;
  font-size: 14px;
  width: 120px;
  text-align: center;
  padding-top: 55px;
  padding-left: 25px;
}

.new-foodstuff__basic-info__content__name {
  width: 100%;
  padding-bottom: 20px;
}

.update-foodstuff__basic-info__group {
  width: 50%;
  margin-right: 20px;
}

.form-label {
  margin-bottom: 2px;
  padding-left: 5px;
  font-size: 12px;
  color: #B9B9B9;
}

.new-foodstuff__basic-info__content__group {
  width: 50%;
}

.new-foodstuff__footer {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 100px !important;
}

.new-foodstuff__basic-info__first-line {
  width: 100%;
}

.new-foodstuff__basic-info__right {
  width: 85%;
  display: block;
}

.new-foodstuff__basic-info__right__first-line {
  display: flex;
}

.new-foodstuff__basic-info__right__second-line {
  padding-top: 2px;
  padding-bottom: 20px;
  display: flex;
}

.new-foodstuff__basic-info__right__third-line {
  display: flex;
}

.update-foodstuff__basic-info__measurement {
  width: 50%;
}

.update-foodstuff__basic-info__weight {
  width: 34%;
  padding-right: 20px;
}

.update-foodstuff__basic-info__barcode {
  width: 33%;
  padding-right: 20px;
}

.new-foodstuff__basic-info__brand {
  width: 33%;
}

.new-foodstuff__nutritional-info {
  padding-top: 30px;
  border: 1px solid #e5e8ee;
  margin-top: 30px;
  border-radius: 16px;
  padding: 24px 20px;
}

.nutritional-info__title h3 {
  font-size: 14px;
  color: #B9B9B9;
  font-weight: 400;
  padding-left: 15px;
}

.nutritional-info__content {
  background-color: white;
  border-radius: 17px;
  display: flex;
  flex-wrap: wrap;
}

.nutritional-info__content__calories {
  width: 50%;
  padding-bottom: 10px;
}

.nutritional-info__content__proteins {
  padding-left: 10px;
  width: 50%;
}

.nutritional-info__content__carbohydrates {
  width: 25%;
}

.nutritional-info__content__sodium {
  padding-left: 10px;
  width: 25%;
}

.nutritional-info__content__fibers {
  padding-left: 10px;
  width: 25%;
}

.nutritional-info__content__saturated-fats {
  padding-left: 10px;
  width: 25%;
}

.dropdown-toggle {
  background-color: #f5f7fb !important;
  border-radius: 9px;
  height: 50px;
  width: 100%;
  color: #B9B9B9;
  border: 1px solid #dce3f0;
  text-align: left;
  font-size: 14px;
}

.dropdown-toggle.show {
  color: #a9a9a9;
  border: 1px solid #c3ccdd;
}

.dropdown-toggle.btn.btn-success::after {
  float: right;
  margin-top: 9px;
  margin-right: 10px;
  font-size: 15px;
}

.dropdown-toggle.btn.btn-success:hover {
  color: #a9a9a9;
  border: 1px solid #c3ccdd;
}

.dropdown-menu.show {
  width: 100%;
  background-color: #f5f7fb !important;
  border: 1px solid #c3ccdd;
}

.dropdown-item {
  color: #a9a9a9;
  min-height: 38px;
  max-width: 100%;
}

.dropdown-item:hover {
  background-color: #fefeff;
  color: #a9a9a9;
}