@font-face {
    font-family: 'Avenir Roman';
    src: url('./fonts/AvenirLTStd-Roman.otf') format('truetype');
}

@font-face {
    font-family: 'Avenir Book';
    src: url('./fonts/AvenirLTStd-Book.otf') format('truetype');
}

@font-face {
    font-family: 'Avenir Heavy';
    src: url('./fonts/AvenirLTStd-Black.otf') format('truetype');
}

* {
    font-family: 'Avenir Roman', sans-serif;
}

.loader {
    position: fixed !important;
    background-color: blue;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(235, 235, 235, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;

    animation: appear 0.6s ease;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}