a {
  text-decoration: none;
}

.login {
  font-size: 12px;
  color: black;
  height: 100vh;
}

.container {
  max-width: none !important;
  display: flex;
}

.right-side {
  display: flex;
  justify-content: center;
  padding-left: 130px;
  padding-right: 130px;
}

.right-side__content {
  padding-top: 40px;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
}

.search-icon {
  color: #D3DDF3 !important;
}

.foodstuffs {
  width: 100%;
}

.right-side__content__header {
}

.right-side__content__header h1 {
  font-size: 24px;
  font-weight: 700;
}

.foodstuffs__content {
  width: 100%;
  min-height: 600px;
  padding-top: 24px;
  border-radius: 17px;
}

.foodstuffs__content h1 {
  font-size: 26px;
  color: #8C8989;
}

.foodstuffs__search {
  display: flex;
  width: 100%;
}

.foodstuff__search__filter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  width: 58px;
  border-radius: 12px;
  border: 2px solid #F7F7F7;
  margin-left: 12px;
}

.foodstuff__search__filter:hover {
  border: 2px solid #F3F3F4;
  background-color: rgba(243, 243, 243, 0.3);
}


.foodstuff__search__filter:active {
  border: 2px solid #F3F3F4;
  background-color: rgba(243, 243, 243, 0.5);
}

.foodstuff__search__filter--pressed {
  border: 2px solid #F3F3F4;
  background-color: rgba(243, 243, 243, 0.5);
}

.foodstuff__search__filter img {
  height: 20px;
  width: 20px;
}

.foodstuffs__list__items {
  min-height: 420px;
}

.foodstuffs__search__name {
  width: 84%;
}

.foodstuffs__button {
  padding-left: 10px;
  width: 16%;
}

.foodstuffs__button button {
  height: 58px;
}

.foodstuffs__search-button {
  border: 0;
  color: white;
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  height: 38px;
  width: 100%;
}

.foodstuffs__list {
  padding: 0;
  min-height: 560px;
  background-color: white;
  margin-top: 20px;
  border-radius: 16px;
  border: 2px solid #F3F3F4;
}

.foodstuffs__footer {
  padding-top: 20px;
  height: 70px;
  bottom: 0 !important;
}

.foodstuffs__create-button {
  border: 0;
  color: white;
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  height: 38px;
  width: 200px;
  float: right;
}

.foodstuffs__list__header {
  background-color: #FAFAFA;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 44px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #868693;
  border-bottom: 1px solid #F3F3F4;
}

.foodstuffs__list__header__image {
  width: 8%;
  text-align: left;
  padding-left: 20px;
}

.foodstuffs__list__header__name {
  width: 20%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.foodstuffs__list__header__type {
  width: 16%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.foodstuffs__list__header__measurement {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.foodstuffs__list__header__brand {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.foodstuffs__list__header__weight {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.foodstuffs__list__header__actions {
  width: 20%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.foodstuffs__list__item {
  width: 100%;
  height: 60px;
  display: flex;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  color: #000000;
  padding-top: 6px;
  align-items: center;
  border-bottom: 1px solid #F3F3F4;
}

.foodstuffs__list__item:hover {
  background-color: #FFFAFB;
}

.foodstuffs__list__item__image {
  width: 8%;
  height: 40px;
  align-items: center;
  display: flex;
  padding-left: 21px;
  padding-right: 20px;
}

.foodstuffs__list__item__image img {
  height: 32px;
  width: 32px;
}

.foodstuffs__list__item__name {
  width: 20%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.foodstuffs__list__item__type {
  width: 16%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;

}

.foodstuffs__list__item__measurement {
  width: 15%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.foodstuffs__list__item__brand {
  width: 15%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.foodstuffs__list__item__brand--not-appliable {
  color: #868693 !important;
}

.foodstuffs__list__item__weight {
  width: 15%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.foodstuffs__list__item__actions {
  width: 20%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.foodstuffs__list__item__actions__edit {
  width: 33%;
  display: flex;
  justify-items: flex-start;
}

.foodstuffs__list__item__actions__use-as-draft {
  width: 33%;
}

.foodstuffs__list__item__actions__use-as-draft img {
  height: 20px;
}

.foodstuffs__list__item__actions button {
  background-color: transparent;
  border: 0;
  /* width: 80%; */
}

.foodstuffs__list__item__actions button:hover {
  background-color: #FFF3F5;
  border: 0;
}

.foodstuffs__list__item__actions button:active {
  background-color: #fceef0 !important;
  border: 0 !important;
}


.foodstuffs__list__item__actions__delete {
  width: 33%;
}

.foodstuffs__search input.form-control {
  height: 38px;
}

.foodstuffs__list__footer {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;
  align-items: center;
  height: 94px;
  width: 100%;
}

.simple-pagination {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.foodstuffs__list__footer button:hover {
  border: 0;
  font-size: 12px;
}

.foodstuffs__list__footer button:disabled {
  background-color: white;
  border: 0;
  color: #a7a4a4 !important;
  font-size: 12px;
}


.foodstuffs__list__footer button:active {
  background-color: rgb(244, 244, 244) !important;
  border: 0;
  color: #818181 !important;
  font-size: 12px;
}

.foodstuffs__list__item__actions__moreSection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.foodstuffs__list__item__actions__moreSection__icon {
  display: flex;
  justify-content: center;
  max-width: 33px;
  max-height: 33px;
}

.foodstuffs__list__item__actions__moreSection__icon button {
  width: 100%;
}

.foodstuffs__list__item__actions__moreSection__dropdown {
  position: absolute;
  background-color: white;
  max-width: 113px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  border-radius: 10px;
  flex-direction: column;
  margin-left: -162px !important;
  margin-top: 18px;
  max-width: 113px !important;
}

.foodstuffs__list__item__actions__moreSection__dropdown__item {
  background-color: transparent;
  border: 0;
  width: 100%;
  color: #38383E;
  text-align: left;
  font-size: 14px;
  padding: 0;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
}


.delete-option {
  color: #ED6767;
}

.delete-option:hover {
  color: #ED6767;
}

.delete-option:active {
  color: #ED6767 !important;
}

.foodstuff__search__filter__dropdown {
  position: absolute;
  background-color: white;
  max-width: 113px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  border-radius: 10px;
  flex-direction: column;
  margin-left: -203px !important;
  margin-top: 62px !important;
}

.foodstuff__search__filter__dropdown.dropdown-menu.show {
  padding-top: 4px;
  padding-bottom: 4px;
  min-width: 202px !important;
}

.foodstuff__search__filter__dropdown div.dropdown-header {
  padding-bottom: 12px;
  color: #38383E;
  font-size: 14px;
  font-weight: 600;
  padding-left: 12px;
}

.foodstuff__search__filter__dropdown__options {
  padding-left: 12px;
  padding-right: 12px;
}

.foodstuff__search__filter__dropdown__option {
  padding-bottom: 10px;
}

.foodstuff__search__filter__dropdown__option__checkbox {
  display: flex;
  color: #38383E;
}

.foodstuff__search__filter__dropdown__option__checkbox__box {
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #D9D9DE;
  background-color: #F3F3F4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.foodstuff__search__filter__dropdown__option__checkbox__box img {
  display: none;
}

.foodstuff__search__filter__dropdown__option__checkbox__label {
  padding-left: 10px;
  font-size: 14px;
}

.checkbox--checked {
  background-color: #FE8398;
  border: 0px;
  color: white;
}

.checkbox--checked img {
  display: block;
}