.foodstuffs__list__item__actions__moreSection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.foodstuffs__list__item__actions__moreSection__icon {
  display: flex;
  justify-content: center;
  max-width: 33px;
  max-height: 33px;
}

.foodstuffs__list__item__actions__moreSection__icon button {
  width: 100%;
}

.foodstuffs__list__item__actions__moreSection__dropdown {
  position: absolute;
  background-color: white;
  max-width: 113px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  border-radius: 10px;
  flex-direction: column;
  margin-left: -162px !important;
  margin-top: 18px;
  max-width: 113px !important;
}

.foodstuffs__list__item__actions__moreSection__dropdown__item {
  background-color: transparent;
  border: 0;
  width: 100%;
  color: #38383E;
  text-align: left;
  font-size: 14px;
  padding: 0;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
}


.delete-option {
  color: #ED6767;
}

.delete-option:hover {
  color: #ED6767;
}

.delete-option:active {
  color: #ED6767 !important;
}

.foodstuffs__list__item__actions__moreSection__dropdown .dropdown-menu.show {
  padding-top: 4px;
  padding-bottom: 4px;
  max-width: 113px !important;
}
