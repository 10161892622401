.n-dashboard {
  padding-left: 112px;
  padding-right: 112px;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 1100px) {
  .n-dashboard {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.n-dashboard__title h1 {
  font-size: 24px;
  font-weight: 600;
}

.n-dashboard__avatar-warning {
  padding: 24px;
  border-radius: 12px;
  border: 2px solid #F3F3F4;
  margin-top: 20px;
}

.n-dashboard__avatar-warning__title {
  font-size: 18px;
  font-weight: 600;
}

.n-dashboard__avatar-warning__subtitle {
  font-size: 14px;
  color: #7A7A86;
  padding-top: 4px;
}

.n-dashboard__avatar-warning__actions {
  display: flex;
  padding-top: 20px;
}

.n-dashboard__avatar-warning__actions__not-now button {
  font-weight: 600;
  font-size: 14px;
  border-radius: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.n-dashboard__avatar-warning__actions__add {
  padding-left: 12px;
}

.n-dashboard__avatar-warning__actions__add button {
  font-weight: 600;
  font-size: 14px;
  border-radius: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.n-dashboard__summary {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.n-dashboard__summary__item {
  padding: 24px;
  border-radius: 12px;
  border: 2px solid #F3F3F4;
  width: 32%;
}

.n-dashboard__summary__item__title {
  font-size: 16px;
}

.n-dashboard__summary__item__data {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}

.n-dashboard__summary__item__value {
  font-size: 32px;
  font-weight: 600;
}

.n-dashboard__summary__item__variance {
  font-size: 14px;
  color: #7A7A86;
  background-color: #ecfdf3;
  border-radius: 16px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 3px;
  color: #027A48;
  font-weight: 600;
  display: flex;
  align-self: center;
}

.n-dashboard__summary__item__variance--decrease {
  color: #b42318;
  background-color: #fef3f2;
}

.n-dashboard__summary__item__variance--decrease img {
  rotate: 180deg;
}

/* .n-dashboard__summary__item__variance img {
  width: 12px;
  height: 12px;
} */

.n-dashboard__summary__item__variance span {
  padding-left: 4px;
}

.n-dashboard__meal-plans {
  margin-top: 20px;
  border: 2px solid #F3F3F4;
  border-radius: 12px;
}

.n-dashboard__meal-plans__header {
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
  border-bottom: 2px solid #F3F3F4;
}

.n-dashboard__meal-plans__header__title {
  display: flex;
}

.n-dashboard__meal-plans__header__title h2 {
  padding-top: 3px;
  font-size: 18px;
  color: #38383e;
  font-weight: 600;
}

.n-dashboard__meal-plans__header__subtitle {
  color: #5F5F68;
  padding-top: 4px;
}

.n-dashboard__meal-plans__header__patients {
  background-color: #fe8398;
  border-radius: 16px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  max-height: max-content;
}

.n-dashboard__meal-plans__actions {
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  border-bottom: 2px solid #F3F3F4;
}

.n-dashboard__patients {
  padding: 0;
  min-height: 560px;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

.n-dashboard__patients__header {
  background-color: #FAFAFA;
  width: 100%;
  height: 44px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #868693;
  border-bottom: 1px solid #F3F3F4;
}

.n-dashboard__patients__header__name {
  width: 25%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.n-dashboard__patients__header__birthdate {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.n-dashboard__patients__header__height {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.n-dashboard__patients__header__weight {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.n-dashboard__patients__header__last-update {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.n-dashboard__patients__header__actions {
  width: 7%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.n-dashboard__patients__last-update {
  width: 15%;
  height: 40px;
  padding-top: 6px;
  padding-left: 20px;
  text-align: left;
}

.n-dashboard__patients__weight {
  width: 15%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.nutritionist__users__item__name {
  width: 25%;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  display: flex;
  align-items: center;
}

.n-dashboard__patients__actions {
  width: 15%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.n-dashboard__patients__actions button {
  background-color: transparent;
  border: 0;
  /* width: 80%; */
}

.n-dashboard__patients__actions button:hover {
  background-color: #FFF3F5;
  border: 0;
}

.n-dashboard__patients__actions button:active {
  background-color: #fceef0 !important;
  border: 0 !important;
}

.n-dashboard__patients__meal-plan {
  width: 15%;
  height: 40px;
  padding-top: 6px;
  padding-left: 20px;
  text-align: left;
}

.n-dashboard__patients__birthdate {
  width: 15%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}
