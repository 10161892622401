a {
  text-decoration: none;
}

.container {
  max-width: none !important;
  display: flex;
}

.patient {
  display: flex;
}

.patient__content {
  width: 100%;
  height: 100vh;
  background-color: white;
  padding-left: 112px;
  padding-right: 112px;
}

@media (max-width: 1210px) {
  .patient__content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.patient__content__lists {
  display: flex;
}

.patient__content__header {
  display: flex;
  justify-content: flex-start;
  padding-top: 40px;
  padding-bottom: 20px;
}

.patient__content__header__previous {
  color: #4A4A51;
  font-size: 14px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
}

.patient__content__header__previous:hover {
  font-weight: 600;
}

.patient__content__header__name {
  color: #4A4A51;
  font-weight: 600;
  font-size: 14px;
  background-color: #F3F3F4;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  border-radius: 6px;
}

.patient__content__header__divisor {
  padding-left: 8px;
  padding-right: 8px;
}

.patient__content__header__divisor img {
  width: 12px;
  height: 12px;
  margin-top: 2px;
}

.simple-pagination {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.patient__patient__users__footer button:hover {
  border: 0;
  font-size: 12px;
}

.patient__patient__users__footer button:disabled {
  background-color: white;
  border: 0;
  color: #a7a4a4 !important;
  font-size: 12px;
}


.patient__patient__users__footer button:active {
  background-color: rgb(244, 244, 244) !important;
  border: 0;
  color: #818181 !important;
  font-size: 12px;
}

.patient__foodstuffs__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.patient__foodstuffs__header h3 {
  display: flex;
  font-size: 16px;
  color: #38383E;
  margin: 0;
  font-weight: 600;
}

.patient__foodstuffs__header__actions button {
  height: 43px;
}

.users__title h3 {
  font-size: 20px;
  padding-top: 12px;
  font-weight: 600;
  height: 43px;
}

.patient__foodstuffs__header__title h3 {
  font-size: 20px;
  padding-top: 4px;
  font-weight: 600;
}

.patient__content__header__title {
  width: 100%;
  padding-top: 2px;
}

.patient__content__header__title a {
  display: flex;
  text-decoration: none;
  color: #38383E;
}

.patient__content__header__title h1 {
  font-size: 24px;
  padding-left: 24px;
}

.patient__content__header__search {
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
}

.patient__content__header__me img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.patient__content__header__me__avatar {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: #FFD9DF;
}

.patient__content__header__invite {
  margin-right: 24px;
}

.patient__content__header__invite button {
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  width: 144px;
}

.patient__content__header__search .inputSection {
  height: 40px;
  padding: 0;
  width: 375px;
}

.patient__users {
  min-height: 420px;
}

.patient__users__item {
  width: 100%;
  height: 60px;
  display: flex;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  color: #000000;
  padding-top: 6px;
  align-items: center;
  border-bottom: 1px solid #F3F3F4;
}

.patient__users__item:hover {
  background-color: #FFFAFB;
}

.patient__meals__foodstuff-icon {
  align-items: center;
  display: flex;
}

.patient__meals__foodstuff-icon img {
  height: 32px;
  width: 32px;
}

.patient__users__item__name {
  width: 25%;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  display: flex;
  align-items: center;
}

.patient__users__item__name p {
  padding-left: 12px;
  padding-top: 4px;
}

.patient__users__item__birthdate {
  width: 18%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;

}

.patient__users__item__measurement {
  width: 15%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.patient__users__item__height {
  width: 15%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.patient__users__item__brand--not-appliable {
  color: #868693 !important;
}

.patient__users__item__weight {
  width: 20%;
  height: 40px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.patient__users__item__actions {
  width: 7%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.patient__users__item__actions__edit {
  width: 33%;
  display: flex;
  justify-items: flex-start;
}

.patient__users__item__actions__use-as-draft {
  width: 33%;
}

.patient__users__item__actions__use-as-draft img {
  height: 20px;
}

.patient__users__item__actions button {
  background-color: transparent;
  border: 0;
  /* width: 80%; */
}

.patient__users__item__actions button:hover {
  background-color: #FFF3F5;
  border: 0;
}

.patient__users__item__actions button:active {
  background-color: #fceef0 !important;
  border: 0 !important;
}


.patient__users__item__actions__delete {
  width: 33%;
}

.foodstuffs__search input.form-control {
  height: 38px;
}

.patient__users__footer {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;
  align-items: center;
  height: 94px;
  width: 100%;
}

.simple-pagination {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.patient__users__footer button:hover {
  border: 0;
  font-size: 12px;
}

.patient__users__footer button:disabled {
  background-color: white;
  border: 0;
  color: #a7a4a4 !important;
  font-size: 12px;
}


.patient__users__footer button:active {
  background-color: rgb(244, 244, 244) !important;
  border: 0;
  color: #818181 !important;
  font-size: 12px;
}

.patient__users__item__actions__moreSection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.patient__users__item__actions__moreSection__icon {
  display: flex;
  justify-content: center;
  max-width: 33px;
  max-height: 33px;
}

.patient__users__item__actions__moreSection__icon button {
  width: 100%;
}

.patient__users__item__actions__moreSection__dropdown {
  position: absolute;
  background-color: white;
  max-width: 113px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  border-radius: 10px;
  flex-direction: column;
  margin-left: -162px !important;
  margin-top: 18px;
  max-width: 113px !important;
}

.patient__users__item__actions__moreSection__dropdown__item {
  background-color: transparent;
  border: 0;
  width: 100%;
  color: #38383E;
  text-align: left;
  font-size: 14px;
  padding: 0;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
}

.patient__users__header {
  background-color: #FAFAFA;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 44px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #868693;
  border-bottom: 1px solid #F3F3F4;
}

.patient__users__header__image {
  width: 8%;
  text-align: left;
  padding-left: 20px;
}

.patient__users__header__name {
  width: 25%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__header__birthdate {
  width: 18%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__header__measurement {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__header__height {
  width: 15%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__header__weight {
  width: 20%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__header__actions {
  width: 7%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.patient__users__item--not-appliable {
  color: #868693 !important;
}

.patient__details {
  min-width: 320px;
  width: 320px;
  min-height: max-content;
  max-height: max-content;
  border-radius: 12px;
  border: 2px solid #F3F3F4;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.01);
  padding: 24px;
}

.patient__details__name {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.patient__details__name__avatar {
  width: 151px;
  height: 151px;
  background-color: #FFF3F5;
  border-radius: 100%;
}

.patient__details__name__avatar img {
  width: 151px;
  height: 151px;
  border-radius: 100%;
}

.patient__details__name h2 {
  font-size: 18px;
  padding-top: 14px;
  font-weight: 600;
}

.patient__details__actions {
  padding-top: 20px;
  padding-bottom: 8px;
  border-bottom: 2px solid #F3F3F4;
}

.patient__details__actions button {
  border-radius: 8px;
}

.patient__details__actions button:first-of-type {
  margin-bottom: 12px;
}

.patient__details__personal {
  padding-top: 22px;
}

.patient__details__personal__title h3 {
  font-size: 16px;
  font-weight: 600;
}

.patient__details__personal__forms {
  padding-top: 18px;
}

.patient__details__personal__forms__measurements {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
}

.patient__details__personal__forms__measurements .inputSection {
  width: 48%;
}

.patient__container {
  display: flex;
}

.add-foodstuff-modal__buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}

.add-foodstuff-modal__button {
  width: 46%;
}

.add-foodstuff-modal__inputs .inputSection {
  margin-top: 24px;
}

.patient__summary {
  width: 100%;
  padding-left: 40px;
}

.patient__summary__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.patient__summary__header h3 {
  font-size: 18px;
  font-weight: 600;
  padding-top: 7px;
  color: #38383E;
}

.patient__summary__header__title {
  display: flex;
  padding-top: 5px;
  justify-content: flex-start;
}

.patient__summary__header__subtitle {
  padding-top: 3px;
  padding-left: 12px;
}

.patient__summary__metrics {
  padding-top: 12px;
  display: flex;
  flex-direction: row;
}

.patient__summary__metrics__total {
  border-radius: 12px;
  border: 2px solid #F3F3F4;
}

.donut-chart {
  position: relative;
  width: 134px;
  height: 134px;
  margin: 0 auto;
}

.donut-chart__middle {
  position: absolute;
  top: 12%;
  left: 12%;
  width: 76%;
  height: 76%;
  background: #fff;
  border-radius: 50%;
  z-index: 10;
}

.donut-chart__title {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  z-index: 11;
  text-align: center;
}

.donut-chart__subtitle {
  padding-top: 8px;
  font-size: 14px;
  line-height: 14px;
  color: #7A7A86;
}

.donut-chart__slice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.patient__summary__metrics__total {
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 20px;
  padding-right: 20px;
  max-height: max-content;
}

.patient__summary__metrics__total__division {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
}

.patient__summary__metrics__total__item {
  display: flex;
  padding-right: 16px;
}

.patient__summary__metrics__total__icon {
  padding-top: 7.5px;
}

.patient__summary__metrics__total__info {
  padding-left: 12px;
  padding-right: 8.8px;
}

.patient__summary__metrics__total__title {
  font-size: 14px;
  font-weight: 500;
  color: #38383E;
}

.patient__summary__metrics__total__subtitle {
  font-size: 12px;
  color: #7A7A86;
  width: max-content;
}

.patient__summary__metrics__total__square {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.square--red {
  background-color: #FE8398;
}

.square--yellow {
  background-color: #FFDD67;
}

.square--blue {
  background-color: #78C9FF;
}

.patient__summary__metrics__total__item:last-of-type {
  padding-right: 0;
}

.patient__summary__metrics__specific {
  width: 100%;
  padding-left: 12px;
}

.patient__summary__metrics__specific__line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}

.patient__summary__metrics__specific__line:last-of-type {
  margin-bottom: 0;
}

.patient__summary__metrics__specific__item {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 2px solid #F3F3F4;
  border-radius: 12px;
  width: 50%;
  margin-right: 12px;
}

.patient__summary__metrics__specific__item:last-of-type {
  margin-right: 0;
}

.patient__summary__metrics__specific h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  padding-top: 8px;
  line-height: 27px;
  margin: 0;
}

.patient__summary__metrics__specific p {
  font-size: 16px;
  line-height: 20px;
  color: #868693;
}

.patient__daily-summary {
  animation: fadeIn 0.5s;
}

.patient__meals {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
}

.patient__meals__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.patient__meals__header__title h3 {
  font-size: 18px;
  font-weight: 600;
}

.patient__meals__header__last-meal p {
  font-size: 14px;
  color: #7A7A86;
}

.patient-meals__table {
  margin-top: 12px;
}

.patient__meals__list {
  padding: 0;
  background-color: blue;
  min-height: 118px;
  background-color: white;
  margin-top: 20px;
  border-radius: 16px;
  border: 1px solid #F3F3F4;
}

.patient__meals__list__header {
  background-color: #FAFAFA;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 44px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 12px;
  color: #868693;
  font-weight: 500;
  border-bottom: 1px solid #FAFAFA;
}

.patient__monthly-summary {
  /* animation: fadeIn 0.5s forwards; */
}

.patient__monthly-summary__weeks {
  /* width: 100%; */
  border: 2px solid #F3F3F4;
  border-radius: 12px;
  padding: 12px;
  padding-bottom: 4px;
  margin-top: 12px;

  animation: fadeIn 0.5s forwards;
}

.patient__monthly-summary__weeks__header {
  padding-bottom: 12px;
}

.patient__monthly-summary__weeks__header h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.patient__monthly-summary__weeks__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.patient__monthly-summary__weeks__item {
  width: 49%;
  display: flex;
  border: 2px solid #F3F3F4;
  border-radius: 12px;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
  justify-content: space-between;
  text-decoration: none;
  color: #000000;
}

.patient__monthly-summary__weeks__item:hover {
  background-color: #FFF3F5;
}

.patient__monthly-summary__weeks__item:active {
  background-color: #FCEEF0;
}

.patient__monthly-summary__weeks__item--selected {
  background-color: #FFF3F5;
  
  animation: expand 0.2s ease-in;
}

@keyframes expand {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.patient__monthly-summary__weeks__item:last-of-type {
  margin-right: 0;
}

.patient__monthly-summary__weeks__item__icon {
  padding-top: 8px;
}

.patient__monthly-summary__weeks__item img {
  width: 20px;
  height: 20px;
}

.patient__monthly-summary__weeks__item__info {
  padding-left: 8px;
}

.patient__monthly-summary__weeks__item__title {
  font-size: 14px;
  font-weight: 600;
}

.patient__monthly-summary__weeks__item__calories {
  font-size: 14px;
  color: #868693;
}

.patient__monthly-summary__weeks__item__left {
  display: flex;
}

.patient__monthly-summary__weeks__item__days {
  font-size: 14px;
  color: #868693;
  display: flex;
  min-width: 110.4px;
}

.patient__monthly-summary__weeks__item__day {
  /* padding-right: 7.5px; */
  font-size: 14px;
  max-width: 16px;
}

.patient__monthly-summary__weeks__item__day:last-of-type {
  padding-right: 0;
}

.patient__monthly-summary__weeks__item__day img {
  width: 16px;
  height: 16px;
}

.patient__monthly-summary__weeks__item__day p {
  max-width: 16px;
  text-align: center;
}

.patient__monthly-summary__days {
  margin-top: 12px;
  margin-bottom: 100px;

  animation: fadeIn 1s forwards;
}

.patient__monthly-summary__day {
  width: 100%;
  border: 2px solid #F3F3F4;
  border-radius: 12px;
  margin-bottom: 4px;

  animation: fadeIn 0.5s forwards;
}

.patient__monthly-summary__day__header {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: #38383E;
  padding: 12px;
  border-radius: 12px;
}

.patient__monthly-summary__day__header:hover {
  background-color: #fcfcfc;
}

.patient__monthly-summary__day__info {
  display: flex;
}

.patient__monthly-summary__day__info__text h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.patient__monthly-summary__day__info__tag {
  padding-left: 12px;
}

.patient__monthly-summary__day__info__text p {
  font-size: 14px;
  color: #7A7A86;
  padding-top: 2px;
}

.patient__monthly-summary__day__toggle {
  display: flex;
  align-items: center;
  padding-right: 10.5px;
}

.patient__monthly-summary__day__toggle img {
  transform: rotate(-90deg);
  filter: invert(0.6);
  transition: 0.1s ease-in;
}

.patient__monthly-summary__day__toggle img:hover {
  filter: invert(0.1);
  transition: 0.1s ease-in;
}

.patient__monthly-summary__day__toggle--hidden img {
  transform: rotate(90deg);
  transition: 0.1s ease-in;
}

.patient__monthly-summary__day__content__metrics {
  display: flex;
}

.patient__monthly-summary__day__content__metric {
  display: flex;
  border-radius: 12px;
  border: 2px solid #F3F3F4;
  width: 25%;
  padding: 16px;
  margin-right: 12px;
}

.patient__monthly-summary__day__content__metric:last-of-type {
  margin-right: 0;
}

.patient__monthly-summary__day__content__metric__icon {
  display: flex;
  align-items: center;
}

.patient__monthly-summary__day__content__metric__info {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
}

.patient__monthly-summary__day__content__metric__title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.patient__monthly-summary__day__content__metric__subtitle {
  font-size: 14px;
  color: #868693;
}

.patient__monthly-summary__day__content {
  padding: 12px;
  padding-top: 0;
  animation: fadeIn 0.5s;
}

.patient__monthly-summary__day__content--hidden {
  padding: 12px;
  padding-top: 0;
  /* animation: fadeOut 1s; */
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.patient__monthly-summary__day__content__title {
  padding-top: 12px;
}

.patient__monthly-summary__day__content__title h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding-top: 12px;
}

.patient__monthly-summary__day__content__icon {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.patient__monthly-summary__day__content__icon img {
  width: 32px;
  height: 32px;
}

.patient__monthly-summary__day__content__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.patient__monthly-summary__day__content__title {
  font-size: 14px;
  padding: 0 !important;
}

.patient__monthly-summary__day__content__subtitle {
  font-size: 12px;
  color: #7A7A86;
}

.daily-meal-summary__row {
  border-bottom: 0 !important;
  padding-bottom: 6px !important;
  padding-top: 6px !important;
  padding-right: 2px !important;
  height: min-content !important;
  min-height: 0 !important;
  /* margin-bottom: 12px; */
}

.patient__monthly-summary__day__content__items {
  padding-top: 6px;
}

.patient__monthly-summary__day__content__time {
  justify-content: flex-end;
  font-size: 14px;
  color: #868693;
}