.simple-pagination__previous {
  width: 33%;
}

.simple-pagination__description {
  width: 33%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #9E9EA9;
}

.simple-pagination__next {
  width: 33%;
  display: flex;
  justify-content: flex-end;
}

.simple-pagination button {
  border: 0;
  font-size: 12px;
  width: 100px;
}