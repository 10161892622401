.simple-pagination__previous {
  width: 33%;
}

.simple-pagination__description {
  width: 33%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #9E9EA9;
}

.simple-pagination__next {
  width: 33%;
  display: flex;
  justify-content: flex-end;
}

.simple-pagination button {
  border: 0;
  font-size: 12px;
  width: 100px;
}

.numeric-pagination {
  display: flex;
  width: 100%;
  height: 32px;
  justify-content: center;
}

.numeric-pagination__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid #ecedf7;
  border-radius: 6px;
  margin-right: 8px;
}

.numeric-pagination__button--last {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid #ecedf7;
  margin-right: 0px !important;
  border-radius: 6px;
}

.numeric-pagination__button:hover {
  background-color: #F7F7F7;
  border: 1px solid #F7F7F7;
}

.numeric-pagination__button img {
  height: 12px;
  width: 12px;
  margin-top: -2px;
}

.disabled-page img {
  color: #adadad;
  filter: grayscale(.7);
}

.next-page {
  margin-right: 0px !important;
}

.numeric-pagination__button {
  text-decoration: none;
  color: white;
  font-size: 12px;
  color: #38383E;
  font-weight: 500;
  padding-top: 4px;
}

.selected-page {
  background-color: #FE8398;
  color: white;
  border: none;
}

.selected-page:hover {
  background-color: #f77c91;
  color: white;
  border: none;
}

.disabled-page {
  color: #adadad;
  border: 1px solid #F7F7F7;
}

.disabled-page img {
  opacity: 0.3;
}

.disabled-page:hover {
  background-color: white;
  color: #adadad;
}
