.dropdown-toggle {
  background-color: #ffffff !important;
  border-radius: 9px;
  height: 58px;
  width: 100%;
  color: #000000;
  border: 1px solid #D9D9DE;
  text-align: left;
  font-size: 14px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 12px;
}

.dropdown-toggle:disabled {
  color: #000000;
  border: 1px solid #D9D9DE;
  opacity: 1;
}

.dropdown-toggle.show {
  color: #000000;
  border: 1px solid #D9D9DE;
}

.dropdown-toggle.btn.btn-success::after {
  float: right;
  margin-top: 9px;
  margin-right: 10px;
  font-size: 15px;
}

.dropdown-toggle.btn.btn-success:hover {
  color: #000000;
  border: 1px solid #D9D9DE;
}

.dropdown-menu.show {
  width: 100%;
  background-color: #ffffff !important;
  border: 0;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 6px !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #F3F3F4 !important;
  max-height: 220px;
  overflow-y: auto;
  scrollbar-width: none;
}

.dropdown-item {
  min-height: 38px;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.dropdown-item__text {
  color: #38383E;
  font-size: 14px;
  padding-left: 3px;
  padding-top: 3px;
}

.dropdown-item:hover {
  background-color: #f8f8f8;
  color: #595959;
}

.dropdown__title {
  color: #9E9EA9;
  width: 100%;
}

.dropdown__title:disabled {
  color: #9E9EA9;
}

.dropdown__title:hover {
  color: #93939f !important;
}

.dropdown__title--selected {
  color: #000000;
}

.dropdown__title--selected:hover {
  color: #000000;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dropdown__description {
  padding-top: 8px;
  color: #868693;
  font-size: 14px;
}

.new-foodstuff__brand__searchSection__input input.form-control {
  margin-left: 12px !important;
}

.dropdown-toggle.btn.btn-success::after {
  display: none;
}

.dropdown__chevron {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.dropdown__chevron--open {
  transform: rotate(180deg);

  transition: transform 0.3s;
}

.dropdown-toggle:disabled .dropdown__chevron {
  display: none;
}