p {
  margin: 0;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

.landing {
  width: 100%;
  height: 100vh;
}

.landing__header {
  width: 100%;
  padding-left: 112px;
  padding-right: 112px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 94px;
}

.landing__header__logo {
  padding-top: 30px;
}

.landing__header__logo img {
  width: 93px;
  height: 31px;
}

.landing__header__menu {
  display: flex;
  padding-top: 33px;
}

.landing__header__menu__item {
  color: #38383E;
  font-size: 20px;
  margin-right: 48px;
}

.landing__header__menu__item:hover {
  color: #fe8398;
  font-weight: 600;
}

.landing__header__menu__item:last-child {
  margin-right: 0;
}

.landing__header__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.landing__header__actions img {
  width: 28px;
  height: 28px;
}

.landing__header__button {
  min-width: 115px;
}

.landing__introduction {
  padding-top: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.landing__introduction__title h1 {
  align-self: center;
  font-size: 48px;
  color: #38383E;
  font-weight: 600;
  max-width: 731px;
  display: flex;
  font-family: 'Avenir Roman';
}

.landing__introduction__description {
  padding-top: 32px;
  font-size: 24px;
  color: #5F5F68;
}

.landing__introduction__button {
  padding-top: 32px;
  min-width: 136px;
}

.landing__sample {
  width: 100%;
  padding-left: 112px;
  padding-right: 112px;
  padding-top: 112px;
}

.landing__sample__content {
  width: 100%;
  background-color: #FFF3F5;
  border-radius: 40px;
  padding: 64px;
  padding-bottom: 44px;
  display: flex;
  justify-content: center;
}

.landing__sample__items {
  display: flex;
  flex-direction: row;
}

.landing__sample__item {
  padding-right: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
}

.landing__sample__item:last-child {
  padding-right: 0;
  padding-bottom: 0;
}

.landing__sample__item img {
  max-height: 482px;
}

.landing__how-it-works {
  padding-top: 112px;
  padding-left: 112px;
  padding-right: 112px;
}

.landing__how-it-works__indicator h2 {
  color: #FE8398;
  font-size: 16px;
}

.landing__how-it-works__title {
  padding-top: 24px;
}

.landing__how-it-works__title h1 {
  font-size: 40px;
  font-weight: 600;
}

.landing__how-it-works__line {
  padding-top: 72px;
}

.landing__how-it-works__vertical-line {
  display: none;
}

.landing__how-it-works__items {
  display: flex;
  justify-content: space-between;
  padding-top: 64px;
}

.landing__how-it-works__item {
  width: 100%;
}

.landing__how-it-works__item__content {
  padding-top: 48px;
  padding-right: 40px;
}

.landing__how-it-works__item__content h3 {
  font-size: 20px !important;
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 600 !important;
}

.landing__how-it-works__item__content p {
  font-size: 16px;
  color: #868693;
  padding-top: 12px;
}

.landing__how-it-works__item__draw {
  display: flex;
}

.landing__how-it-works__item__draw img {
  height: 24px;
  width: 24px;
  margin-top: -18px;
}

.landing__how-it-works__item__number {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  background-color: #FE8398;
}

.landing__how-it-works__line {
  margin-bottom: -124px;
  margin-left: 40px;
}

.landing__how-it-works__line img {
  width: 67%;
}

.landing__how-it-works__item h3 {
  padding-top: 48px;
  font-size: 24px;
  font-weight: 600;
}

.landing__feedbacks {
  height: 391px;
  width: 100%;
  margin-top: 112px;
  background-color: #FE8398;
  padding-left: 112px;
  padding-right: 112px;
  padding-top: 80px;
}

.landing__feedbacks__items {
  display: flex;
  width: 95%;
  overflow-x: hidden;
}

.landing__feedbacks__item {
  height: 231px;
  min-width: 365px;
  max-width: 365px;
  background-color: white;
  border-radius: 24px;
  padding: 24px;
  margin-right: 24px;
}

.landing__feedbacks__item__stars img {
  padding-right: 4px;
}

.landing__feedbacks__item__text {
  padding-top: 24px;
  font-size: 16px;
}

.landing__feedbacks__item__author {
  padding-top: 24px;
  display: flex;
  align-items: center;
}

.landing__feedbacks__item__author img {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  margin-right: 16px;
}

.landing__feedbacks__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 5%;
}

.landing__feedbacks__button {
  height: 48px;
  width: 48px;
  background-color: #FFD9DF;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing__feedbacks__button:hover {
  background-color: #FFB3C0;
}

.landing__feedbacks__content {
  display: flex;
}

.landing__nutritionists {
  padding: 112px;
}

.landing__nutritionists__title {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

.landing__nutritionists__title h1 {
  font-size: 40px;
  font-weight: 600;
}

.landing__nutritionists__description {
  padding-top: 20px;
  font-size: 20px;
  color: #7A7A86;
  text-align: center;
}

.landing__nutritionists__content {
  display: flex;
  padding-top: 72px;
  justify-content: space-between;
}

.landing__nutritionists__paragraphs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding-right: 72px;
}

.landing__nutritionists__paragraph:first-child {
  padding-top: 0;
}

.landing__nutritionists__paragraph {
  padding-top: 68px;
}

.landing__nutritionists__paragraph h3 {
  font-size: 24px;
  font-weight: 600;
}

.landing__nutritionists__paragraph p {
  padding-top: 12px;
  font-size: 16px;
  color: #7A7A86;
}

.landing__nutritionists__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.landing__nutritionists__image img {
  width: 601px;
  height: 579px;
}

.landing__help {
  padding: 80px 112px;
  width: 100%;
  background-color: #FFF3F5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landing__help__form {
  display: flex;
  flex-direction: column;
  width: 576px;
  background-color: white;
  border-radius: 16px;
  padding: 40px;
}

.landing__help__form__input {
  margin-top: 16px;
}

.landing__help__form__inputArea {
  margin-top: 16px;
}

.landing__help__content {
  padding-right: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing__help__title {
  font-size: 40px;
  font-weight: 600;
  padding-top: 32px;
}

.landing__help__title h1 {
  font-size: 40px;
  font-weight: 600;
}

.landing__help__title h2 {
  font-size: 40px;
  font-weight: 600;
}

.landing__help__description {
  padding-top: 8px;
  font-size: 20px;
  color: #7A7A86;
  max-width: 630px;
}

.landing__help__actions {
  padding-top: 32px;
  display: flex;
  width: 100%;
}

.landing__help__input {
  width: 100%;
  padding-right: 16px;
}

.landing__help__button {
  padding-top: 24px;
  width: 100%;
}

.landing__footer {
  border-top: 1px solid #D9D9DE;
  display: flex;
  padding-left: 112px;
  padding-right: 112px;
  color: #7A7A86;
}

.landing__footer__copyright {
  display: flex;
  padding-top: 10px !important;
}

.landing__footer__termsAndPrivacy {
  display: flex;
  align-items: center;
  padding-top: 4px;
  text-decoration: none;
  color: #7A7A86;
  font-weight: 600;
}

.landing__footer__termsAndPrivacy:hover {
  color: #94949c;
}

.landing__footer img {
  height: 20px;
  width: 62px;
}

.landing__download {
  padding: 74px 112px;
}

.landing__download__content {
  background-color: #FE8398;
  display: flex;
  border-radius: 40px;
  padding: 0 80px;
}

.landing__download__image img {
  max-width: 475px;
  max-height: 503px;
}

.landing__download__info {
  padding-left: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing__download__info h1 {
  color: white;
  font-weight: 600;
}

.landing__download__description {
  padding-top: 20px;
}

.landing__download__description p {
  color: white;
}

.landing__download__buttons {
  padding-top: 24px;
}

.landing__download__google {
  padding-left: 10px;
}

.landing__header__actions__login {
  margin-right: 20px;
  font-weight: 100;
}

.landing__header__actions img {
  width: 28px;
  height: 28px;
}

.menu {
  width: 100%;
}

.menu__header {
  display: flex;
  justify-content: flex-end;
  height: 94px;
  align-items: center;
  padding-right: 39px;
}

.menu__header__close img {
  width: 28px;
  height: 28px;
}

.menu__items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 24px;
}

.menu__items a {
  color: #38383E;
  font-size: 20px;
  margin-top: 24px;
}

.menu__items a:first-child {
  margin-top: 0;
}

.menu__item:hover {
  color: #7a7a7b;
}

.menu__actions {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  padding-top: 32px;
}

.menu__actions .menu__actions__login button {
  margin-top: 20px;
}

.menu__actions button {
  min-height: 54px !important;
}

@media (max-width: 1456px) {
  .landing__sample__item {
    padding-right: 24px;
    padding-bottom: 24px;
    display: flex;
    justify-content: center;
  }

  .landing__sample__item img {
    max-height: 400px;
  }

  .landing__sample__calorie-tracker {
    width: 50%;
    padding-bottom: 24px;
  }

  .landing__sample__meal-tracker {
    width: 50%;
  }

  .landing__sample__water-tracker {
    width: 100%;
  }
}


@media (max-width: 1300px) {
  .landing__nutritionists__image img {
    max-height: 500px;
  }

  .landing__nutritionists__paragraphs {
    padding-top: 68px;
    max-width: 100%;
    padding-right: 72px;
  }

  .landing__nutritionists__paragraph {
    padding-top: 60px;
    padding-right: 0;
  }

  .landing__download__image img {
    max-height: 440px;
  }
}

@media (max-width: 1256px) {
  .landing__sample__item img {
    max-height: 360px;
  }

  .landing__download__image img {
    max-height: 394px;
  }
}

@media (max-width: 1158px) {
  .landing__download__content {
    background-color: #FE8398;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  .landing__download__info {
    text-align: center;
  }

  .landing__download__info p {
    text-align: center;
  }

  .landing__download__description p {
    text-align: center;
  }

  .landing__download__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1126px) {
  .landing__sample__item img {
    max-height: 280px;
  }

  .landing__sample__calorie-tracker {
    width: 100%;
  }

  .landing__sample__meal-tracker {
    width: 100%;
  }

  .landing__sample__water-tracker {
    width: 100%;
  }

  .landing__header__actions__login {
    display: none;
  }

  .landing__nutritionists__image img {
    max-height: 440px;
  }
}

@media (max-width: 976px) {
  .landing__sample__item img {
    max-height: 220px;
  }

  .landing__nutritionists__image img {
    max-height: 360px;
  }
}

@media (max-width: 768px) {
  .menu__header {
    display: flex;
    justify-content: flex-end;
    height: 94px;
    align-items: center;
    padding-right: 25px;
  }

  .landing__header {
    padding-left: 24px;
    padding-right: 24px;
  }

  .landing__header__menu {
    display: none
  }

  .landing__introduction {
    padding-left: 24px;
    padding-right: 24px;
  }

  .landing__sample {
    padding-top: 83px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .landing__sample__content {
    padding: 40px 20px;
  }

  .landing__sample__item {
    padding: 0;
    padding-bottom: 24px;
  }

  .landing__sample__item img {
    width: 305px;
    max-height: 449px;
  }

  .landing__sample__water-tracker {
    width: 100%;
    padding-bottom: 0;
  }

  .landing__sample__calorie-tracker img {
    height: 367px;
  }

  .landing__how-it-works {
    padding-left: 24px;
    padding-right: 24px;
  }

  h1 {
    font-size: 32px !important;
  }

  .landing__how-it-works__items {
    flex-direction: column;
    padding-top: 72px;
  }

  .landing__how-it-works__item:first-child {
    margin-top: 0;
  }

  .landing__how-it-works__item {
    display: flex;
    flex-direction: row !important;
    width: 100%;
    margin-top: 235px;
  }

  .landing__how-it-works__item__content {
    padding-top: 0;
    padding-right: 0;
    padding-left: 40px;
  }

  .landing__how-it-works__basic {
    padding-left: 50px;
  }

  .landing__how-it-works__meals {
    padding-left: 25px;
  }

  .landing__how-it-works__progress {
    padding-left: 10px;
  }

  .landing__how-it-works__progress .landing__how-it-works__item__content {
    padding-left: 20px !important;
  }

  .landing__how-it-works__line {
    display: none;
  }

  .landing__how-it-works__vertical-line {
    margin-top: 70px;
    display: block;
    width: 0px;
  }

  .landing__how-it-works__content {
    display: flex;
    flex-direction: flex;
  }

  .landing__nutritionists {
    padding: 60px 24px;
  }

  .landing__nutritionists__content {
    flex-direction: column;
  }

  .landing__nutritionists__description {
    font-size: 20px;
  }

  .landing__nutritionists__paragraphs {
    padding: 0;
    padding-top: 48px;
    order: 2;
    width: 100%;
  }

  .landing__nutritionists__paragraph {
    padding-top: 54px;
  }

  .landing__nutritionists__paragraph h3 {
    font-size: 20px;
  }

  .landing__nutritionists__paragraph p {
    font-size: 16px;
  }

  .landing__nutritionists__image {
    width: 100%;
    padding: 0;
    margin: 0;
    padding-right: 20px;
  }

  .landing__nutritionists__image img {
    max-width: 343px;
    max-height: 330px;
    padding: 0;
    margin: 0;
  }

  .landing__help__content {
    padding-left: 24px;
    padding-right: 24px;
    height: 100%;
  }

  .landing__help__actions {
    flex-direction: column;
    max-width: 100%;
  }

  .landing__help__input {
    width: 100%;
    padding-right: 0;
  }

  .landing__help__button {
    width: 100%;
    padding-top: 16px;
  }

  .landing__footer {
    margin-top: 0;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: center;
  }

  .landing__footer img {
    display: none;
  }

  .landing__feedbacks {
    padding-left: 24px;
    padding-right: 24px;
  }

  .landing__header__button {
    min-width: 115px;
  }

  button {
    max-height: 46px;
    margin-top: 2px;
  }

  .landing__introduction__title h1 {
    font-size: 32px;
  }

  .landing__introduction__description {
    font-size: 20px;
  }

  .landing__help {
    display: flex;
    flex-direction: column;
    padding: 40px 24px;
  }

  .landing__help__title {
    text-align: center;
    font-size: 30px;
  }

  .landing__help__form {
    width: 100%;
    padding: 24px;
    margin-top: 40px;
  }

  .landing__help__content {
    padding: 0 16px;
  }

  .landing__help__title h1 {
    font-size: 24px !important;
    font-weight: 600;
  }

  .landing__help__image {
    display: flex;
    justify-content: center;
  }

  .landing__help__description p {
    font-size: 20px !important;
    text-align: center;
  }

  .landing__help__button button {
    max-height: 56px;
  }

  .landing__download__image img {
    width: 358px;
    height: 394px;
  }

  .landing__download {
    background-color: #FE8398;
    padding: 80px 0;
    margin: 0;
  }

  .landing__download__info {
    align-items: flex-start;
    padding: 0;
    padding-top: 40px;
  }


  .landing__download__info {
    text-align: left;
  }

  .landing__download__info p {
    text-align: left;
  }

  .landing__download__description p {
    text-align: left;
  }

  .landing__download__buttons img {
    max-width: 170px;
    width: 100%;
    min-width: 100px;
  }

  .landing__footer__termsAndPrivacy {
    text-align: right;
    padding-top: 4px;
  }

  .landing__sample__items {
    flex-wrap: wrap;
  }
}