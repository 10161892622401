.confirmation-modal__description {
  color: #c0c0c0;
  font-size: 13px;
  text-align: center;
}

.confirmation-modal {
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 450px;
}

.confirmation-modal__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 0 auto;
}

.confirmation-modal__title {
  padding-top: 24px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.confirmation-modal__description {
  color: #7A7A86;
  font-size: 14px;
  padding-top: 14px;
}

.confirmation-modal__actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  width: 100%;
}

.confirmation-modal__actions button {
  width: 300px;
  margin-top: 14px;
}

.confirmation-modal__icon img {
  height: 50px;
}
