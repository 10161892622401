.default-button button {
  width: 100%;
  background-color: #FE8398;
  border: none;
  border-radius: 16px;
  height: 54px;
  font-size: 16px;
  font-weight: 900;
  padding-top: 9px;
}

.default-button button:hover {
  background-color: #f0768a;
  border: none;
}

.default-button button:active {
  background-color: #e66d82 !important;
  border: none;
}


.default-button--secondary button {
  border: 1px solid #D9D9DE !important;
  background-color: white;
  font-weight: 100;
  color: #000000;
}

.default-button--secondary button:hover {
  background-color: #F3F3F4 !important;
  color: #000000;
}

.default-button--tertiary button {
  background-color: white;
  border: 1px solid #FE8398;
  border-radius: 12px;
  font-size: 14px;
  color: #FE8398;
  min-width: 120px;
}

.default-button--tertiary button:hover {
  background-color: #FE8398 !important;
  border: 1px solid #FE8398 !important;
  color: white;
  min-width: 120px;
}

.default-button--quaternary button {
  background-color: white;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  color: #FE8398;
  min-width: 60px;
}

.default-button--quaternary button:hover {
  background-color: white !important;
  color: #e8677c;
}

.default-button--quaternary button:active {
  background-color: white !important;
  color: #e05d73 !important;
}

.default-button--small button {
  height: 40px;
}

.default-button--disabled button {
  background-color: #C7C7CD !important;
  color: #7A7A86;
  border: none;
}

.default-button--secondary--disabled button {
  color: #c6c4c4 !important;
  background-color: white !important;
  border: 1px solid #e6e6ea !important;
}

.default-button--tertiary--disabled button {
  color: #c6c4c4 !important;
  background-color: white !important;
  border: 1px solid #e6e6ea !important;
}

.default-button--quaternary--disabled button {
  color: #c6c4c4 !important;
  background-color: white !important;
}